/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Categories } from "@soliguide/common";

export const CATEGORIES_TITLE: { [key in Categories]?: string } = {
  [Categories.HEALTH]: "CAT_SANTE_TITLE",
  [Categories.PSYCHOLOGICAL_SUPPORT]: "CAT_PSYCHOLOGIE_TITLE",
  [Categories.FRENCH_COURSE]: "CAT_COURS_FRANCAIS_TITLE",
  [Categories.DOMICILIATION]: "CAT_DOMICILIATION_TITLE",
  [Categories.WIFI]: "CAT_WIFI_TITLE",
  [Categories.ELECTRICAL_OUTLETS_AVAILABLE]: "CAT_PRISE_TITLE",
  [Categories.FOOD]: "CAT_ALIMENTATION_TITLE",
  [Categories.DAY_HOSTING]: "CAT_ACCUEIL_JOUR_TITLE",
  [Categories.INFORMATION_POINT]: "CAT_POINT_INFO_TITLE",
  [Categories.CLOTHING]: "CAT_VETEMENTS_TITLE",
};
