<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="my-3 autocomplete" *ngIf="!currentLocationExist">
  <div class="row">
    <div class="col-md-6 col-12 d-flex">
      <input
        id="typeahead-http"
        type="text"
        class="form-control"
        [class.is-invalid]="searchFailed"
        [ngbTypeahead]="locationAutocomplete"
        [placeholder]="'CITY_ADDRESS_BOROUGH' | translate"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        [editable]="false"
        (selectItem)="selectItem($event)"
        [disabled]="geolocateLoading"
      />
      <button
        type="button"
        class="ms-2 btn btn-primary"
        [disabled]="geolocateLoading"
        (click)="updateLocation.emit()"
      >
        <fa-icon [icon]="faMagnifyingGlass" class="text-white"></fa-icon>
      </button>
      <ng-template #rt let-r="result" let-t="term">
        <fa-icon [icon]="faMapPin" class="me-3"></fa-icon>
        <ngb-highlight [result]="r.properties.label" [term]="t"></ngb-highlight>
      </ng-template>
    </div>
    <div class="col-md-12 col-12">
      <div *ngIf="searching" class="p-2 loading-text">
        <fa-icon [spin]="true" class="me-2" [icon]="faSpinner"></fa-icon>
        {{ "SEARCH_IN_PROGRESS" | translate }} ...
      </div>
      <div class="invalid-feedback" *ngIf="searchFailed">
        {{ "SEARCH_NO_RESULT_LOCATION" | translate }}
      </div>

      <button
        *ngIf="!searching"
        type="button"
        class="btn btn-link py-2 px-0 color-primary"
        (click)="getLocation()"
      >
        <fa-icon [icon]="faLocationDot" class="me-2"></fa-icon
        >{{ "SEARCH_AROUND_ME" | translate }}
      </button>
    </div>
  </div>
</div>

<div class="my-3 d-flex" id="current-location" *ngIf="currentLocationExist">
  <div class="me-2">
    <fa-icon [icon]="faLocationDot" class="me-1"></fa-icon>
  </div>
  <div>
    <span class="d-block">{{ search.location.label }}</span>
    <button
      *ngIf="!searching"
      type="button"
      class="btn btn-link py-0 px-0 text-primary"
      (click)="currentLocationExist = false"
    >
      {{ "SEARCH_MODIFIER_ADRESSE" | translate }}
    </button>
  </div>
</div>
