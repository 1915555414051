<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<h1>{{ "GRAPHICAL_CHOICE" | translate }}</h1>
<div class="subtitle-primary">
  {{ "PERSONALIZE_WIDGET_APPEARANCE" | translate }}
</div>
<div class="row pt-3 my-4">
  <div class="row justify-content-center">
    <div class="col-12">
      <app-search-results
        [places]="mockPlacesList"
        [disabled]="true"
      ></app-search-results>
    </div>
  </div>
  <div class="row justify-content-center align-items-end">
    <div class="col-12 col-md-4 col-lg-3">
      <label for="bsPrimary" class="form-label">{{
        "PRACTICAL_INFO_COLOR" | translate
      }}</label>
      <div class="input-group">
        <span class="input-group-text">#</span>
        <input
          type="color"
          class="form-control form-control-color"
          id="bsPrimary"
          [attr.aria-label]="'MAIN_COLOR' | translate"
          [(ngModel)]="formValue.theme[WidgetThemeProperties.BS_PRIMARY]"
          (ngModelChange)="setColor(WidgetThemeProperties.BS_PRIMARY)"
        />
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-3">
      <label for="bsPrimaryDark" class="form-label">{{
        "HOVERED_PRACTICAL_INFO_COLOR" | translate
      }}</label>
      <div class="input-group">
        <span class="input-group-text">#</span>
        <input
          type="color"
          class="form-control form-control-color"
          id="bsPrimaryDark"
          [attr.aria-label]="'HOVER_COLOR' | translate"
          [(ngModel)]="formValue.theme[WidgetThemeProperties.BS_PRIMARY_DARK]"
          (ngModelChange)="setColor(WidgetThemeProperties.BS_PRIMARY_DARK)"
        />
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-3">
      <label for="bsPrimaryLight" class="form-label">{{
        "SECONDARY_INFO_COLOR" | translate
      }}</label>
      <div class="input-group">
        <span class="input-group-text">#</span>
        <input
          type="color"
          class="form-control form-control-color"
          id="bsPrimaryLight"
          [attr.aria-label]="'SECONDARY_COLOR' | translate"
          [(ngModel)]="formValue.theme[WidgetThemeProperties.BS_PRIMARY_LIGHT]"
          (ngModelChange)="setColor(WidgetThemeProperties.BS_PRIMARY_LIGHT)"
        />
      </div>
    </div>
  </div>
</div>
