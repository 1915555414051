<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<section>
  <div class="notfound">
    <div class="d-flex">
      <div id="maintenance-pick" class="col-6">
        <img
          src="assets/svg/maintenance.svg"
          alt="Aucun résultat de recherche"
        />
      </div>
      <div id="maintenance-text" class="text-start py-3 px-3 col-6">
        <h1>{{ "OOPS" | translate }}</h1>
        <h2>{{ "NO_SEARCH_RESULT" | translate }}</h2>
        {{ "WIDGET_404" | translate }} :
        <a
          routerLink="https://doc-widget.soliguide.fr/"
          [attr.aria-label]="'WIDGET_DOCUMENTATION' | translate"
          [title]="'WIDGET_DOCUMENTATION' | translate"
        >
        </a>
      </div>
    </div>
  </div>
</section>
