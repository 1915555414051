<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<h1>{{ "LAST_THING" | translate }}</h1>
<div class="subtitle-primary">
  {{ "ACCEPTATION_GCU" | translate }}
</div>
<div class="row pt-3 my-4">
  <a
    [title]="'GCU' | translate"
    routerLink="/cgu"
    target="_blank"
    rel="noopener noreferrer"
    class="subtitle-primary gcu_link"
  >
    {{ "READ_GCU" | translate }}
  </a>
  <div class="fw-bold mb-4">
    <p>
      {{ "WIDGET_SOLIGUIDE_INFO" | translate }}
    </p>
    <p>
      {{ "CONFIDENTIALITY_STATEMENT" | translate }}
      <a
        [title]="'GCU' | translate"
        routerLink="/cgu"
        target="_blank"
        rel="noopener noreferrer"
        >ici</a
      >.
    </p>
    <p>
      {{ "AMENDMENT_CLAUSE" | translate }}
    </p>
  </div>
  <div class="d-flex justify-content-center mb-4">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        name="acceptGcu"
        id="acceptGcu"
        [checked]="formValue.gcu"
        (click)="toggleGcu()"
      />
      <label for="acceptGcu" class="form-check-label">{{
        "ACCEPT_GCU" | translate
      }}</label>
    </div>
  </div>
</div>
