/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";

export const smoothCollapse = trigger("smoothCollapse", [
  state(
    "initial",
    style({
      height: "0",
      overflow: "hidden",
      opacity: "0",
      position: "relative",
      top: "-20px",
      padding: "0.5rem",
    })
  ),
  state(
    "final",
    style({
      overflow: "hidden",
      opacity: "1",
      position: "relative",
      top: "-0px",
      padding: "1rem",
    })
  ),
  transition("initial=>final", animate("500ms")),
  transition("final=>initial", animate("500ms")),
]);
