<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<h1>{{ "PERSONALIZE_WIDGET_PUBLICS" | translate }}</h1>
<div class="subtitle-primary">
  {{ "CHOOSE_PUBLICS" | translate }}
</div>
<div class="row pt-3 my-4">
  <div class="col-12 col-md-3">
    <app-publics-dropdown
      label="GENDER"
      [prop]="PublicsElement.GENDER"
      [formValue]="formValue"
    ></app-publics-dropdown>
  </div>
  <div class="col-12 col-md-3">
    <app-publics-dropdown
      label="ADMINISTRATIVE_SITUATION"
      [prop]="PublicsElement.ADMINISTRATIVE"
      [formValue]="formValue"
    ></app-publics-dropdown>
  </div>
  <div class="col-12 col-md-3">
    <app-publics-dropdown
      label="FAMILY_SITUATION"
      [prop]="PublicsElement.FAMILY"
      [formValue]="formValue"
    ></app-publics-dropdown>
  </div>
  <div class="col-12 col-md-3">
    <app-publics-dropdown
      label="OTHER_SITUATION"
      [prop]="PublicsElement.OTHER"
      [formValue]="formValue"
    ></app-publics-dropdown>
  </div>
</div>
