<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div id="search" [ngClass]="search.widgetId" *ngIf="search">
  <div *ngIf="!maskLocationSearch" class="px-2 px-md-0">
    <div [class]="langClass">
      <h3 *ngIf="search.category">
        <span class="text-primary">{{ title | translate }}</span>
        <span> {{ "A_PROXIMITE_DE" | translate }}</span>
      </h3>
    </div>
    <app-autocomplete-location
      [class]="langClass"
      [search]="search"
      (updateLocation)="updateLocation()"
    ></app-autocomplete-location>
  </div>
  <app-search-results
    [loading]="loading"
    [places]="places"
    [search]="search"
  ></app-search-results>
</div>
