<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<h1>{{ "YOUR_ORGANIZATION" | translate }}</h1>
<div class="subtitle-primary">
  {{ "TO_SEND_YOU_UPDATES" | translate }}
</div>

<form #userForm="ngForm" class="mb-5 mt-4">
  <div class="form-group mb-4">
    <label for="organization">{{ "ORGANIZATION_NAME" | translate }}</label>
    <span class="required">*</span>
    <input
      type="text"
      class="form-control"
      id="organization"
      required
      [(ngModel)]="formValue.organizationName"
      name="organization"
      #organizationName="ngModel"
    />
    <div
      [hidden]="
        organizationName.valid ||
        organizationName.untouched ||
        (organizationName.touched && organizationName.value?.length)
      "
      class="alert alert-danger"
    >
      {{ "ORGANIZATION_NAME_REQUIRED" | translate }}
    </div>
  </div>

  <div class="form-group">
    <label for="email">{{ "EMAIL_ADDRESS" | translate }}</label>
    <span class="required">*</span>
    <input
      type="email"
      class="form-control"
      id="email"
      required
      [(ngModel)]="formValue.email"
      name="email"
      #email="ngModel"
    />
    <div
      [hidden]="
        email.valid || email.untouched || (email.touched && email.value?.length)
      "
      class="alert alert-danger"
    >
      {{ "MAIL_REQUIRED" | translate }}
    </div>
    <div
      [hidden]="email.pristine || emailRegexp.test(formValue.email)"
      class="alert alert-danger"
    >
      {{ "MAIL_INVALID" | translate }}
    </div>
  </div>
</form>
