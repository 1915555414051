<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<h1>{{ "PERSONALIZE_WIDGET_ACCESS_CONDITIONS" | translate }}</h1>
<div class="subtitle-primary">
  {{ "CHOOSE_ACCESS_CONDITIONS" | translate }}
</div>

<div class="row pt-3 my-4">
  <h2 class="subtitle-primary">
    {{ "ACCESS_CONDITIONS_TYPES" | translate }} <span class="required">*</span>
  </h2>
  <div class="d-flex justify-content-center mb-2">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        name="allPlaces"
        id="allPlaces"
        [(ngModel)]="allChecked"
        (click)="checkAll()"
      />
      <label for="allPlaces" class="form-check-label">{{
        "ALL_REFERENCED_ITEMS" | translate
      }}</label>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-12 col-md-6 col-lg-3 mb-2 mb-lg-0 px-lg-0">
      <div class="list-group h-100">
        <div class="list-group-item d-flex align-items-center px-4 h-100">
          <input
            type="checkbox"
            name="anyAccessConditions"
            id="anyAccessConditions"
            class="form-check-input flex-shrink-0 me-2 mt-0"
            (click)="toggleCheckbox(ModalitiesElement.UNCONDITIONAL)"
            [checked]="isChecked(ModalitiesElement.UNCONDITIONAL)"
          />
          <label
            for="anyAccessConditions"
            class="form-check-label flex-shrink-1 mt-2"
          >
            <strong>{{ "DIRECT_ACCESS" | translate }}</strong>
          </label>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 mb-2 mb-lg-0 pe-lg-0">
      <div class="list-group h-100 mb-md-2">
        <div class="list-group-item d-flex align-items-center px-4 h-100">
          <input
            type="checkbox"
            name="onRegistration"
            id="onRegistration"
            class="form-check-input flex-shrink-0 me-2 mt-0"
            (click)="toggleCheckbox(ModalitiesElement.ON_REGISTRATION)"
            [checked]="isChecked(ModalitiesElement.ON_REGISTRATION)"
          />
          <label
            for="onRegistration"
            class="form-check-label flex-shrink-1 mt-2"
          >
            <strong>{{
              "ACCESS_CONDITION_ON_REGISTRATION" | translate
            }}</strong>
          </label>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 mb-2 mb-lg-0 pe-lg-0">
      <div class="list-group h-100 mb-md-2">
        <div class="list-group-item d-flex align-items-center px-4 h-100">
          <input
            type="checkbox"
            name="onOrientation"
            id="onOrientation"
            class="form-check-input flex-shrink-0 me-2 mt-0"
            (click)="toggleCheckbox(ModalitiesElement.ON_ORIENTATION)"
            [checked]="isChecked(ModalitiesElement.ON_ORIENTATION)"
          />
          <label
            for="onOrientation"
            class="form-check-label flex-shrink-1 mt-2"
          >
            <strong>{{ "ACCESS_CONDITION_ON_ORIENTATION" | translate }}</strong>
          </label>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3 pe-lg-0">
      <div class="list-group h-100">
        <div class="list-group-item d-flex align-items-center px-4 h-100">
          <input
            type="checkbox"
            name="onAppointment"
            id="onAppointment"
            class="form-check-input flex-shrink-0 me-2 mt-0"
            (click)="toggleCheckbox(ModalitiesElement.ON_APPOINTMENT)"
            [checked]="isChecked(ModalitiesElement.ON_APPOINTMENT)"
          />
          <label
            for="onAppointment"
            class="form-check-label flex-shrink-1 mt-2"
          >
            <strong>{{ "ACCESS_CONDITION_ON_APPOINTMENT" | translate }}</strong>
          </label>
        </div>
      </div>
    </div>
  </div>

  <h2 class="subtitle-primary mt-4">
    {{ "SPECIFIC_ACCESS_CONDITIONS" | translate }}
  </h2>
  <div class="row">
    <div class="col-12 col-md-6 mb-2 mb-lg-0">
      <div class="list-group h-100">
        <div class="list-group-item d-flex align-items-center px-4 h-100">
          <input
            type="checkbox"
            name="prmAccess"
            id="prmAccess"
            class="form-check-input flex-shrink-0 me-2 mt-0"
            (click)="toggleCheckbox(ModalitiesElement.PRM)"
            [checked]="isChecked(ModalitiesElement.PRM)"
          />
          <label for="prmAccess" class="form-check-label flex-shrink-1 mt-2">
            <strong>{{
              "REDUCED_MOBILITY_ACCESS_PLACES_ONLY" | translate
            }}</strong>
          </label>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mb-2 mb-lg-0">
      <div class="list-group h-100">
        <div class="list-group-item d-flex align-items-center px-4 h-100">
          <input
            type="checkbox"
            name="free"
            id="free"
            class="form-check-input flex-shrink-0 me-2 mt-0"
            (click)="toggleCheckbox(ModalitiesElement.PRICE)"
            [checked]="isChecked(ModalitiesElement.PRICE)"
          />
          <label for="free" class="form-check-label flex-shrink-1 mt-2">
            <strong>{{ "FREE_PLACES_ONLY" | translate }}</strong>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
