/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import type { Languages } from "../types";
import { Themes } from "../../themes";

export const LANGUAGES_LIST: Languages = {
  aa: {
    name: "Afar (Erythtrée / Ethiopie)",
    nativeName: "Afaraf",
  },
  ab: {
    name: "Abkhaz",
    nativeName: "аҧсуа",
  },
  af: {
    name: "Afrikaans",
    nativeName: "Afrikaans",
  },
  ak: {
    name: "Akan (Ghana Cote ivoire)",
    nativeName: "Akan",
  },
  am: {
    name: "Amharique",
    nativeName: "አማርኛ",
  },
  an: {
    name: "Aragonais",
    nativeName: "Aragonés",
  },
  ar: {
    name: "Arabe",
    nativeName: "العربية",
  },
  ay: {
    name: "Aymara",
    nativeName: "aymar aru",
  },
  az: {
    name: "Azerbaïdjanais",
    nativeName: "azərbaycan dili",
  },
  ba: {
    name: "Bachkir",
    nativeName: "башҡорт теле",
  },
  be: {
    name: "Biélorusse",
    nativeName: "Беларуская",
  },
  bg: {
    name: "Bulgare",
    nativeName: "български език",
  },
  bh: {
    name: "Bihari",
    nativeName: "भोजपुरी",
  },
  bi: {
    name: "Bislama",
    nativeName: "Bislama",
  },
  bm: {
    name: "Bambara",
    nativeName: "bamanankan",
  },
  bn: {
    name: "Bengali (Bangladesh)",
    nativeName: "বাংলা",
  },
  bo: {
    name: "Tibétain Standard, Tibétain, Central",
    nativeName: "",
  },
  brc: {
    name: "Berbere Chleuh",
    nativeName: "Chleuh",
  },
  brk: {
    name: "Kabyle",
    nativeName: "Kabyle",
  },
  bs: {
    name: "Bosniaque",
    nativeName: "bosanski jezik",
  },
  ca: {
    name: "Catalan; Valencien",
    nativeName: "Català",
  },
  ce: {
    name: "Tchétchène",
    nativeName: "нохчийн мотт",
  },
  co: {
    name: "Corse",
    nativeName: "corsu, lingua corsa",
  },
  cs: {
    name: "Tchèque",
    nativeName: "česky, čeština",
  },
  cv: {
    name: "Tchouvache",
    nativeName: "чӑваш чӗлхи",
  },
  da: {
    name: "Danois",
    nativeName: "dansk",
  },
  dar: {
    name: "Dari, persan Afghan",
    nativeName: "دری",
  },
  de: {
    name: "Allemand",
    nativeName: "Deutsch",
  },
  dv: {
    name: "Divehi; Dhivehi; Maldivien;",
    nativeName: "ދިވެހި",
  },
  ee: {
    name: "Ewe",
    nativeName: "Eʋegbe",
  },
  el: {
    name: "Grec moderne",
    nativeName: "Ελληνικά",
  },
  en: {
    name: "Anglais",
    nativeName: "English",
  },
  eo: {
    name: "Esperanto",
    nativeName: "Esperanto",
  },
  es: {
    name: "Espagnol",
    nativeName: "español, castellano",
  },
  et: {
    name: "Estonien",
    nativeName: "eesti, eesti keel",
  },
  eus: {
    name: "Basque",
    nativeName: "Euskara",
  },
  fa: {
    name: "Persan, Farsi Iranien",
    nativeName: "فارسی",
  },
  ff: {
    name: "Fula; Fulah; Pulaar; Pular",
    nativeName: "Fulfulde, Pulaar, Pular",
  },
  fi: {
    name: "Finlandais",
    nativeName: "suomi, suomen kieli",
  },
  fo: {
    name: "Féroïen",
    nativeName: "føroyskt",
  },
  fr: {
    name: "Français",
    nativeName: "français, langue française",
  },
  ga: {
    name: "Irlandais",
    nativeName: "Gaeilge",
  },
  gl: {
    name: "Galicien",
    nativeName: "Galego",
  },
  gn: {
    name: "Guaraní",
    nativeName: "Avañeẽ",
  },
  gsw: {
    name: "Alsacien",
    nativeName: "Alemannisch, Schwyzerdütsch, Elsässisch",
  },
  gu: {
    name: "Gujarati",
    nativeName: "ગુજરાતી",
  },
  he: {
    name: "Hebreux (Moderne)",
    nativeName: "עברית",
  },
  hi: {
    name: "Hindi",
    nativeName: "हिन्दी, हिंदी",
  },
  ho: {
    name: "Hiri Motu",
    nativeName: "Hiri Motu",
  },
  hr: {
    name: "Croate",
    nativeName: "hrvatski",
  },
  ht: {
    name: "Haitien; Creole",
    nativeName: "Kreyòl ayisyen",
  },
  hu: {
    name: "Hongrois",
    nativeName: "Magyar",
  },
  hy: {
    name: "Arménien",
    nativeName: "Հայերեն",
  },
  id: {
    name: "Indonésien",
    nativeName: "Bahasa Indonesia",
  },
  ig: {
    name: "Igbo (Nigéria)",
    nativeName: "Asụsụ Igbo",
  },
  ii: {
    name: "Nuosu",
    nativeName: "ꆈꌠ꒿ Nuosuhxop",
  },
  is: {
    name: "Islandais",
    nativeName: "Íslenska",
  },
  it: {
    name: "Italien",
    nativeName: "Italiano",
  },
  ja: {
    name: "Japonais",
    nativeName: "日本語 (にほんご／にっぽんご)",
  },
  ka: {
    name: "Georgien",
    nativeName: "ქართული",
  },
  kg: {
    name: "Kongo",
    nativeName: "KiKongo",
  },
  ki: {
    name: "Kikuyu, Gikuyu",
    nativeName: "Gĩkũyũ",
  },
  kj: {
    name: "Kwanyama, Kuanyama",
    nativeName: "Kuanyama",
  },
  kk: {
    name: "Kazakh",
    nativeName: "Қазақ тілі",
  },
  km: {
    name: "Khmer",
    nativeName: "ភាសាខ្មែរ",
  },
  ko: {
    name: "Coréen",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
  },
  ks: {
    name: "Kashmiri",
    nativeName: "کٲشُر, कॉशुर",
  },
  ku: {
    name: "Kurde",
    nativeName: "Kurdî",
  },
  kw: {
    name: "Cornouaillais",
    nativeName: "Kernewek",
  },
  ky: {
    name: "Kirghiz, Kirghizistan",
    nativeName: "кыргыз тили",
  },
  lb: {
    name: "Luxembourgeois, Letzeburgesch",
    nativeName: "Lëtzebuergesch",
  },
  lg: {
    name: "Luganda",
    nativeName: "Luganda",
  },
  li: {
    name: "Limbourgeois, Limbourg, Limbourg",
    nativeName: "Limburgs",
  },
  ln: {
    name: "Lingala",
    nativeName: "Lingála",
  },
  lo: {
    name: "Lao",
    nativeName: "ພາສາລາວ",
  },
  lsf: {
    name: "Langue des Signes Française",
    nativeName: "Langue des Signes Française, LSF",
  },
  lt: {
    name: "Lituanien",
    nativeName: "lietuvių kalba",
  },
  lu: {
    name: "Luba-Katanga",
    nativeName: "",
  },
  lv: {
    name: "Letton",
    nativeName: "latviešu valoda",
  },
  mg: {
    name: "Malgache",
    nativeName: "Malagasy fiteny",
  },
  mh: {
    name: "Marshallese",
    nativeName: "Kajin M̧ajeļ",
  },
  mk: {
    name: "Macedonien",
    nativeName: "македонски јазик",
  },
  ml: {
    name: "Malayalam",
    nativeName: "മലയാളം",
  },
  mn: {
    name: "Mongol",
    nativeName: "монгол",
  },
  mr: {
    name: "Marathi (Marāṭhī)",
    nativeName: "मराठी",
  },
  ms: {
    name: "Malais",
    nativeName: "bahasa Melayu",
  },
  mt: {
    name: "Maltais",
    nativeName: "Malti",
  },
  my: {
    name: "Birman",
    nativeName: "ဗမာစာ",
  },
  na: {
    name: "Nauru",
    nativeName: "Ekakairũ Naoero",
  },
  nd: {
    name: "Ndébélés Nord",
    nativeName: "isiNdebele",
  },
  ne: {
    name: "Nepalais",
    nativeName: "नेपाली",
  },
  ng: {
    name: "Ndonga",
    nativeName: "Owambo",
  },
  nl: {
    name: "Néerlandais",
    nativeName: "Nederlands, Vlaams",
  },
  nr: {
    name: "Ndébélés Sud",
    nativeName: "isiNdebele",
  },
  nv: {
    name: "Navajo, Navaho",
    nativeName: "Diné bizaad, Dinékʼehǰí",
  },
  ny: {
    name: "Chichewa; Chewa; Nyanja",
    nativeName: "chiCheŵa, chinyanja",
  },
  oc: {
    name: "Occitan",
    nativeName: "Occitan",
  },
  om: {
    name: "Oromo",
    nativeName: "Afaan Oromoo",
  },
  os: {
    name: "Ossète, Ossétique",
    nativeName: "ирон æвзаг",
  },
  pa: {
    name: "Panjabi, Punjabi, Penjabi",
    nativeName: "ਪੰਜਾਬੀ, پنجابی",
  },
  pac: {
    name: "Pachto",
    nativeName: "پښتو",
  },
  pe: {
    name: "Peul",
    nativeName: "",
  },
  pl: {
    name: "Polonais",
    nativeName: "polski",
  },
  ps: {
    name: "Pashto, Pushto",
    nativeName: "پښتو",
  },
  pt: {
    name: "Portugais",
    nativeName: "Português",
  },
  qu: {
    name: "Quechua (Pérou)",
    nativeName: "Runa Simi, Kichwa",
  },
  rcf: {
    name: "Créole Réunionnais, réunionnais",
    nativeName: "kréol rénioné",
  },
  rm: {
    name: "Romanche",
    nativeName: "rumantsch grischun",
  },
  rn: {
    name: "Kirundi (Burundi)",
    nativeName: "kiRundi",
  },
  ro: {
    name: "Roumain, Moldave",
    nativeName: "română",
  },
  rom: {
    name: "Romani, Tsigane",
    nativeName: "romani ćhib, Romani šib, Romanó",
  },
  ru: {
    name: "Russe",
    nativeName: "русский язык",
  },
  rw: {
    name: "Kinyarwanda",
    nativeName: "Ikinyarwanda",
  },
  sa: {
    name: "Sanskrit (Saṁskṛta)",
    nativeName: "संस्कृतम्",
  },
  sd: {
    name: "Sindhi",
    nativeName: "سنڌي, सिन्धी",
  },
  sg: {
    name: "Sango (Centreafrique)",
    nativeName: "yângâ tî sängö",
  },
  si: {
    name: "Cinghalais (Sri-lanka)",
    nativeName: "",
  },
  sk: {
    name: "Slovaque",
    nativeName: "slovenčina",
  },
  sl: {
    name: "Slovène",
    nativeName: "slovenščina",
  },
  sm: {
    name: "Samoan",
    nativeName: "gagana faa Samoa",
  },
  sn: {
    name: "Shona (Zimbabwe)",
    nativeName: "chiShona",
  },
  so: {
    name: "Somali",
    nativeName: "Soomaaliga, af Soomaali",
  },
  son: {
    name: "Soninke",
    nativeName: "Sonike",
  },

  sq: {
    name: "Albanais",
    nativeName: "Shqip",
  },
  sr: {
    name: "Serbe",
    nativeName: "српски језик",
  },
  ss: {
    name: "Swati",
    nativeName: "SiSwati",
  },
  st: {
    name: "Sotho du Sud",
    nativeName: "Sesotho",
  },
  sv: {
    name: "Suédois",
    nativeName: "svenska",
  },
  sw: {
    name: "Swahili",
    nativeName: "Kiswahili",
  },
  swb: {
    name: "Mahorais",
    nativeName: "Mahorais, Shimahorais",
  },
  ta: {
    name: "Tamil",
    nativeName: "தமிழ்",
  },
  te: {
    name: "Telugu",
    nativeName: "తెలుగు",
  },
  tg: {
    name: "Tajik",
    nativeName: "тоҷикӣ",
  },
  th: {
    name: "Thaïlandais",
    nativeName: "ไทย",
  },
  tk: {
    name: "Turkmène",
    nativeName: "Türkmen, Түркмен",
  },
  tl: {
    name: "Tagalog / Philipain",
    nativeName: "Wikang Tagalog",
  },
  tr: {
    name: "Turc",
    nativeName: "Türkçe",
  },
  ts: {
    name: "Tsonga",
    nativeName: "Xitsonga",
  },
  tt: {
    name: "Tatar",
    nativeName: "татарча, ",
  },
  tw: {
    name: "Twi",
    nativeName: "Twi",
  },
  ty: {
    name: "Tahitien",
    nativeName: "Reo Tahiti",
  },
  ug: {
    name: "Ouïgour, ouïghour",
    nativeName: "U",
  },
  uk: {
    name: "Ukrainien",
    nativeName: "українська",
  },
  ur: {
    name: "Urdu",
    nativeName: "اردو",
  },
  uz: {
    name: "Ouzbek",
    nativeName: "zbek, Ўзбек",
  },
  vi: {
    name: "Vietnamien",
    nativeName: "Tiếng Việt",
  },
  wo: {
    name: "Wolof",
    nativeName: "Wollof",
  },
  yi: {
    name: "Yiddish",
    nativeName: "ייִדיש",
  },
  zh: {
    name: "Chinois",
    nativeName: "中文 (Zhōngwén), 汉语, 漢語",
  },
};

export const LANGUAGES_LIST_MAP_KEY: Array<string | null> =
  Object.keys(LANGUAGES_LIST);

export const LANGUAGES_LIST_MAP_KEY_FOR_LOGS: Array<string | null> =
  LANGUAGES_LIST_MAP_KEY.concat(null);

export const CURRENT_LANGUAGE = "current_language";

// TODO : Refactor this when Languages by Theme will be merged
export const SOLIGUIDE_LANGUAGES_BY_THEME: Record<Themes, string[]> = {
  [Themes.SOLIGUIA_AD]: ["ca", "en", CURRENT_LANGUAGE],
  [Themes.SOLIGUIA_ES]: ["es", "en", CURRENT_LANGUAGE],
  [Themes.SOLIGUIDE_FR]: ["fr", "en", CURRENT_LANGUAGE],
};

// In the interests of eco-design, copy the weblate translation files to avoid having to translate languages from the front,
// knowing that the translations are stable and will not change.
export const ALL_LANGUAGES_CODES: Record<string, Record<string, string>> = {
  ar: {
    LANGUE_AA: "لغة عفارية",
    LANGUE_AB: "لغة أبخازية",
    LANGUE_AE: "أفستية",
    LANGUE_AF: "لغة أفريقانية",
    LANGUE_AK: "لغة أكانية",
    LANGUE_AM: "لغة أمهرية",
    LANGUE_AN: "لغة أراغونية",
    LANGUE_AR: "لغة عربية",
    LANGUE_AS: "لغة آسامية",
    LANGUE_AV: "لغة أوارية",
    LANGUE_AY: "لغة أيمارا",
    LANGUE_AZ: "لغة أذرية",
    LANGUE_BA: "لغة باشقير",
    LANGUE_BE: "لغة بيلاروسية",
    LANGUE_BG: "لغة بلغارية",
    LANGUE_BH: "قائمة اللغات البهارية",
    LANGUE_BI: "لغة بسلاما",
    LANGUE_BM: "لغة بمبرية",
    LANGUE_BN: "لغة بنغالية، Bangla",
    LANGUE_BO: "تبتية، Tibetan، Central",
    LANGUE_BR: "لغة بريتانية",
    LANGUE_BRC: "بربر شلوح",
    LANGUE_BRK: "القبائلية",
    LANGUE_BS: "لغة بوسنوية",
    LANGUE_CA: "لغة كتالانية",
    LANGUE_CE: "لغة شيشانية",
    LANGUE_CH: "لغة تشاموروية",
    LANGUE_CO: "لغة كورسية",
    LANGUE_CR: "لغة كري",
    LANGUE_CS: "لغة تشيكية",
    LANGUE_CU:
      "السلافونية الكنسية القديمة، سلافونية كنسية، سلافونية كنسية قديمة",
    LANGUE_CV: "لغة تشوفاش",
    LANGUE_CY: "لغة ويلزية",
    LANGUE_DA: "لغة دنماركية",
    LANGUE_DAR: "داري",
    LANGUE_DE: "لغة ألمانية",
    LANGUE_DV: "ديفهي، Dhivehi، Maldivian",
    LANGUE_DZ: "لغة دزونكا",
    LANGUE_EE: "Ewe",
    LANGUE_EL: "لغة يونانية (حديثة)",
    LANGUE_EN: "لغة إنجليزية",
    LANGUE_EO: "إسبرانتو",
    LANGUE_ES: "لغة إسبانية",
    LANGUE_ET: "لغة إستونية",
    LANGUE_EUS: "لغة بشكنشية",
    LANGUE_FA: "لغة فارسية (Farsi)",
    LANGUE_FF: "لغة فولانية، Fulah، Pulaar، Pular",
    LANGUE_FI: "لغة فنلندية",
    LANGUE_FJ: "لغة فيجية",
    LANGUE_FO: "لغة فاروية",
    LANGUE_FR: "لغة فرنسية",
    LANGUE_FY: "اللغة الفريزية الغربية",
    LANGUE_GA: "لغة أيرلندية",
    LANGUE_GD: "لغة غيلية اسكتلندية، Gaelic",
    LANGUE_GL: "لغة غاليسية",
    LANGUE_GN: "غوارانية",
    LANGUE_GSW: "الألزاسي",
    LANGUE_GU: "لغة غوجاراتية",
    LANGUE_GV: "لغة منكية",
    LANGUE_HA: "لغة هوسية",
    LANGUE_HE: "لغة عبرية (حديثة)",
    LANGUE_HI: "لغة هندية",
    LANGUE_HO: "هيري موتو",
    LANGUE_HR: "لغة كرواتية",
    LANGUE_HT: "لغة كريولية هايتية، Haitian Creole",
    LANGUE_HU: "لغة مجرية",
    LANGUE_HY: "لغة أرمنية",
    LANGUE_HZ: "لغة هيريرو",
    LANGUE_IA: "إنترلنغوا",
    LANGUE_ID: "لغة إندونيسية",
    LANGUE_IE: "Interlingue",
    LANGUE_IG: "لغة الإيجبو",
    LANGUE_II: "Nuosu",
    LANGUE_IK: "Inupiaq",
    LANGUE_IO: "لغة إيدو",
    LANGUE_IS: "لغة آيسلندية",
    LANGUE_IT: "لغة إيطالية",
    LANGUE_IU: "لغة إنكتيتوتية",
    LANGUE_JA: "لغة يابانية",
    LANGUE_JV: "لغة جاوية",
    LANGUE_KA: "لغة جورجية",
    LANGUE_KG: "لغة كونغو",
    LANGUE_KI: "لغة كيكويو، Gikuyu",
    LANGUE_KJ: "Kwanyama، Kuanyama",
    LANGUE_KK: "لغة قازاقية",
    LANGUE_KL: "لغة غرينلاندية، Greenlandic",
    LANGUE_KM: "لغة خميرية",
    LANGUE_KN: "لغة كنادية",
    LANGUE_KO: "لغة كورية",
    LANGUE_KR: "لغة كانورية",
    LANGUE_KS: "لغة كشميرية",
    LANGUE_KU: "لغة كردية",
    LANGUE_KV: "لغة كومية",
    LANGUE_KW: "لغة كورنية",
    LANGUE_KY: "لغة قيرغيزية",
    LANGUE_LA: "لغة لاتينية",
    LANGUE_LB: "لغة لوكسمبورغية، Letzeburgesch",
    LANGUE_LG: "Ganda",
    LANGUE_LI: "لغة ليمبورغية، Limburgan، Limburger",
    LANGUE_LN: "لينغالا",
    LANGUE_LO: "لاو",
    LANGUE_LSF: "لغة الإشارة الفرنسية",
    LANGUE_LT: "لغة ليتوانية",
    LANGUE_LU: "لغة تشيلوبا",
    LANGUE_LV: "لغة لاتفية",
    LANGUE_MG: "لغة ملغاشية",
    LANGUE_MH: "لغة مارشالية",
    LANGUE_MI: "لغة ماورية",
    LANGUE_MK: "لغة مقدونية",
    LANGUE_ML: "لغة ماليالامية",
    LANGUE_MN: "لغة منغولية",
    LANGUE_MR: "لغة مراثية",
    LANGUE_MS: "لغة ملايو",
    LANGUE_MT: "لغة مالطية",
    LANGUE_MY: "لغة بورمية",
    LANGUE_NA: "لغة ناورونية",
    LANGUE_NB: "بوكمول",
    LANGUE_ND: "Northern Ndebele",
    LANGUE_NE: "لغة نيبالية",
    LANGUE_NG: "Ndonga",
    LANGUE_NL: "لغة هولندية",
    LANGUE_NN: "نينوشك",
    LANGUE_NO: "لغة نرويجية",
    LANGUE_NR: "لغة نديبلي جنوبية",
    LANGUE_NV: "نافاهو، Navaho",
    LANGUE_NY: "لغة الشيشيوا، Chewa، Nyanja",
    LANGUE_OC: "لغة قسطانية",
    LANGUE_OJ: "Ojibwe، Ojibwa",
    LANGUE_OM: "لغة الأورومو",
    LANGUE_OR: "لغة الأوريا",
    LANGUE_OS: "لغة أوسيتية، Ossetic",
    LANGUE_PA: "لغة بنجابية، Punjabi",
    LANGUE_PAC: "لغة بشتوية، Pushto",
    LANGUE_PE: "بول Peul",
    LANGUE_PI: "بالية",
    LANGUE_PL: "لغة بولندية",
    LANGUE_PS: "لغة بشتوية، Pushto",
    LANGUE_PT: "لغة برتغالية",
    LANGUE_QU: "كتشوا",
    LANGUE_RCF: "ريونيون الكريول",
    LANGUE_RM: "لغة رومانشية",
    LANGUE_RN: "لغة كيروندية",
    LANGUE_RO: "لغة رومانية",
    LANGUE_ROM: "الغجر",
    LANGUE_RU: "لغة روسية",
    LANGUE_RW: "كينيارواندا",
    LANGUE_SA: "لغة سنسكريتية",
    LANGUE_SC: "لغة سردينية",
    LANGUE_SD: "لغة سندية",
    LANGUE_SE: "لغة سامي الشمالية",
    LANGUE_SG: "السانغوية",
    LANGUE_SI: "لغة سنهالية، Sinhalese",
    LANGUE_SK: "لغة سلوفاكية",
    LANGUE_SL: "لغة سلوفينية",
    LANGUE_SM: "لغة ساموية",
    LANGUE_SN: "لغة الشونا",
    LANGUE_SO: "لغة صومالية",
    LANGUE_SON: "سونيك Sonike",
    LANGUE_SQ: "لغة ألبانية",
    LANGUE_SR: "لغة صربية",
    LANGUE_SS: "لغة سوازي",
    LANGUE_ST: "لغة سوتية",
    LANGUE_SU: "اللغات السوندية",
    LANGUE_SV: "لغة سويدية",
    LANGUE_SW: "لغة سواحلية",
    LANGUE_SWB: "ماهوريس",
    LANGUE_TA: "لغة تاميلية",
    LANGUE_TE: "لغة تيلوغوية",
    LANGUE_TG: "لغة طاجيكية",
    LANGUE_TH: "لغة تايلندية",
    LANGUE_TI: "لغة تغرينية",
    LANGUE_TK: "لغة تركمانية",
    LANGUE_TL: "لغة تاغالوغية",
    LANGUE_TN: "لغة تسوانية",
    LANGUE_TO: "التونغية (Tonga Islands)",
    LANGUE_TR: "لغة تركية",
    LANGUE_TS: "لغة تسونجا",
    LANGUE_TT: "لغة تترية",
    LANGUE_TW: "توي",
    LANGUE_TY: "لغة تاهيتية",
    LANGUE_UG: "لغة أويغورية",
    LANGUE_UK: "لغة أوكرانية",
    LANGUE_UR: "لغة أردوية",
    LANGUE_UZ: "لغة أوزبكية",
    LANGUE_VE: "لغة فيندية",
    LANGUE_VI: "لغة فيتنامية",
    LANGUE_VO: "فولابوك",
    LANGUE_WA: "لغة والون",
    LANGUE_WO: "لغة ولوفية",
    LANGUE_XH: "اللغة الخوسية",
    LANGUE_YI: "لغة يديشية",
    LANGUE_YO: "لغة يوربا",
    LANGUE_ZA: "Zhuang، Chuang",
    LANGUE_ZH: "لغة صينية",
    LANGUE_ZU: "لغة زولوية",
  },
  ca: {
    LANGUE_AA: "De lluny",
    LANGUE_AB: "abkhàs",
    LANGUE_AE: "Avestan",
    LANGUE_AF: "Afrikàans",
    LANGUE_AK: "Akan",
    LANGUE_AM: "amàric",
    LANGUE_AN: "aragonès",
    LANGUE_AR: "àrab",
    LANGUE_AS: "assameses",
    LANGUE_AV: "Ava",
    LANGUE_AY: "aimara",
    LANGUE_AZ: "àzeri",
    LANGUE_BA: "Bashkir",
    LANGUE_BE: "Bielorús",
    LANGUE_BG: "búlgar",
    LANGUE_BH: "Bihari",
    LANGUE_BI: "Bichelamar",
    LANGUE_BM: "Bambara",
    LANGUE_BN: "bengalí",
    LANGUE_BO: "tibetà",
    LANGUE_BR: "bretó",
    LANGUE_BRC: "Berber Chleuh",
    LANGUE_BRK: "Kabyle",
    LANGUE_BS: "bosnià",
    LANGUE_CA: "català",
    LANGUE_CE: "Txetxè",
    LANGUE_CH: "Chamorro",
    LANGUE_CO: "Còrsega",
    LANGUE_CR: "Crida",
    LANGUE_CS: "txec",
    LANGUE_CU: "vell eslau",
    LANGUE_CV: "Txuvaix",
    LANGUE_CY: "gal·lès",
    LANGUE_DA: "danès",
    LANGUE_DAR: "Dari",
    LANGUE_DE: "alemany",
    LANGUE_DV: "Maldivia",
    LANGUE_DZ: "Dzongkha",
    LANGUE_EE: "Ovella",
    LANGUE_EL: "Grec modern",
    LANGUE_EN: "Anglès",
    LANGUE_EO: "esperanto",
    LANGUE_ES: "espanyol",
    LANGUE_ET: "estonià",
    LANGUE_EUS: "basc",
    LANGUE_FA: "persa",
    LANGUE_FF: "Fulani",
    LANGUE_FI: "finès",
    LANGUE_FJ: "Fijians",
    LANGUE_FO: "feroès",
    LANGUE_FR: "francès",
    LANGUE_FY: "Frisó occidental",
    LANGUE_GA: "irlandès",
    LANGUE_GD: "escocès",
    LANGUE_GL: "gallec",
    LANGUE_GN: "guaraní",
    LANGUE_GSW: "alsacià",
    LANGUE_GU: "Gujarati",
    LANGUE_GV: "Manx",
    LANGUE_HA: "Hausa",
    LANGUE_HE: "hebreu",
    LANGUE_HI: "hindi",
    LANGUE_HO: "Hiri motu",
    LANGUE_HR: "croat",
    LANGUE_HT: "crioll haitiano",
    LANGUE_HU: "hongarès",
    LANGUE_HY: "armeni",
    LANGUE_HZ: "Herero",
    LANGUE_IA: "Interlingua",
    LANGUE_ID: "indonesi",
    LANGUE_IE: "Occidental",
    LANGUE_IG: "Igbo",
    LANGUE_II: "Yi",
    LANGUE_IK: "Inupiak",
    LANGUE_IO: "Faig",
    LANGUE_IS: "islandès",
    LANGUE_IT: "italià",
    LANGUE_IU: "Inuktitut",
    LANGUE_JA: "japonès",
    LANGUE_JV: "javanès",
    LANGUE_KA: "georgiana",
    LANGUE_KG: "Kikongo",
    LANGUE_KI: "Kikuyu",
    LANGUE_KJ: "Kuanyama",
    LANGUE_KK: "Kazakh",
    LANGUE_KL: "groenlandès",
    LANGUE_KM: "Khmer",
    LANGUE_KN: "Kannada",
    LANGUE_KO: "coreà",
    LANGUE_KR: "Kanouri",
    LANGUE_KS: "Caixmir",
    LANGUE_KU: "kurd",
    LANGUE_KV: "Komi",
    LANGUE_KW: "Còrnic",
    LANGUE_KY: "kirguiz",
    LANGUE_LA: "llatí",
    LANGUE_LB: "luxemburguesa",
    LANGUE_LG: "Ganda",
    LANGUE_LI: "Limburg",
    LANGUE_LN: "lingala",
    LANGUE_LO: "Lao",
    LANGUE_LSF: "Llengua de signes francesa",
    LANGUE_LT: "lituà",
    LANGUE_LU: "Luba",
    LANGUE_LV: "letó",
    LANGUE_MG: "malgaix",
    LANGUE_MH: "Marshallesa",
    LANGUE_MI: "Maori de Nova Zelanda",
    LANGUE_MK: "macedoni",
    LANGUE_ML: "malayalam",
    LANGUE_MN: "mongol",
    LANGUE_MR: "Marathi",
    LANGUE_MS: "Malai",
    LANGUE_MT: "maltès",
    LANGUE_MY: "birmà",
    LANGUE_NA: "Nauruan",
    LANGUE_NB: "Bokmål noruec",
    LANGUE_ND: "Sindebele",
    LANGUE_NE: "Nepalès",
    LANGUE_NG: "Ndonga",
    LANGUE_NL: "holandès",
    LANGUE_NN: "Nynorsk noruec",
    LANGUE_NO: "noruec",
    LANGUE_NR: "Nrebele",
    LANGUE_NV: "Navajo",
    LANGUE_NY: "Chichewa",
    LANGUE_OC: "occità",
    LANGUE_OJ: "Ojibwe",
    LANGUE_OM: "Oromo",
    LANGUE_OR: "Oriya",
    LANGUE_OS: "osseta",
    LANGUE_PA: "panjabi",
    LANGUE_PAC: "Pashto",
    LANGUE_PE: "Fulani",
    LANGUE_PI: "Pali",
    LANGUE_PL: "polonès",
    LANGUE_PS: "Pashto",
    LANGUE_PT: "portuguès",
    LANGUE_QU: "Quechua",
    LANGUE_RCF: "Crioll réunionnais",
    LANGUE_RM: "romanx",
    LANGUE_RN: "Kirundi",
    LANGUE_RO: "romanès",
    LANGUE_ROM: "romaní",
    LANGUE_RU: "rus",
    LANGUE_RW: "kinyarwanda",
    LANGUE_SA: "sànscrit",
    LANGUE_SC: "sard",
    LANGUE_SD: "Sindhi",
    LANGUE_SE: "Sami del Nord",
    LANGUE_SG: "Sango",
    LANGUE_SI: "cingalès",
    LANGUE_SK: "eslovac",
    LANGUE_SL: "eslovè",
    LANGUE_SM: "samoà",
    LANGUE_SN: "Shona",
    LANGUE_SO: "Somali",
    LANGUE_SON: "sonike",
    LANGUE_SQ: "albanès",
    LANGUE_SR: "serbi",
    LANGUE_SS: "Swati",
    LANGUE_ST: "Sotho meridional",
    LANGUE_SU: "Sundanès",
    LANGUE_SV: "suec",
    LANGUE_SW: "suahili",
    LANGUE_SWB: "Mahorés",
    LANGUE_TA: "Tamil",
    LANGUE_TE: "Telugu",
    LANGUE_TG: "Tadjik",
    LANGUE_TH: "tailandès",
    LANGUE_TI: "Tigrigna",
    LANGUE_TK: "turcomà",
    LANGUE_TL: "Tagalog",
    LANGUE_TN: "Tswana",
    LANGUE_TO: "Tonga",
    LANGUE_TR: "turc",
    LANGUE_TS: "Tsonga",
    LANGUE_TT: "Tàrtar",
    LANGUE_TW: "Twi",
    LANGUE_TY: "Tahitià",
    LANGUE_UG: "Uigur",
    LANGUE_UK: "Ucraïnès",
    LANGUE_UR: "Urdú",
    LANGUE_UZ: "Uzbek",
    LANGUE_VE: "Venda",
    LANGUE_VI: "vietnamita",
    LANGUE_VO: "Volapük",
    LANGUE_WA: "Való",
    LANGUE_WO: "Wolof",
    LANGUE_XH: "Xhosa",
    LANGUE_YI: "Yiddish",
    LANGUE_YO: "ioruba",
    LANGUE_ZA: "Zhuang",
    LANGUE_ZH: "xinès",
    LANGUE_ZU: "Zulu",
  },
  en: {
    LANGUE_AA: "Afar",
    LANGUE_AB: "Abkhazian",
    LANGUE_AE: "Avestan",
    LANGUE_AF: "Afrikaans",
    LANGUE_AK: "Akan",
    LANGUE_AM: "Amharic",
    LANGUE_AN: "Aragonese",
    LANGUE_AR: "Arabic",
    LANGUE_AS: "Assamese",
    LANGUE_AV: "Avaric",
    LANGUE_AY: "Aymara",
    LANGUE_AZ: "Azerbaijani",
    LANGUE_BA: "Bashkir",
    LANGUE_BE: "Belarusian",
    LANGUE_BG: "Bulgarian",
    LANGUE_BH: "Bihari languages",
    LANGUE_BI: "Bislama",
    LANGUE_BM: "Bambara",
    LANGUE_BN: "Bengali",
    LANGUE_BO: "Tibetan",
    LANGUE_BR: "Breton",
    LANGUE_BRC: "Berber Chleuh",
    LANGUE_BRK: "Kabyle",
    LANGUE_BS: "Bosnian",
    LANGUE_CA: "Catalan",
    LANGUE_CE: "Chechen",
    LANGUE_CH: "Chamorro",
    LANGUE_CO: "Corsican",
    LANGUE_CR: "Cree",
    LANGUE_CS: "Czech",
    LANGUE_CU:
      "Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic",
    LANGUE_CV: "Chuvash",
    LANGUE_CY: "Welsh",
    LANGUE_DA: "Danish",
    LANGUE_DAR: "Dari",
    LANGUE_DE: "German",
    LANGUE_DV: "Divehi, Dhivehi, Maldivian",
    LANGUE_DZ: "Dzongkha",
    LANGUE_EE: "Ewe",
    LANGUE_EL: "Greek, Modern (1453–)",
    LANGUE_EN: "English",
    LANGUE_EO: "Esperanto",
    LANGUE_ES: "Spanish, Castilian",
    LANGUE_ET: "Estonian",
    LANGUE_EUS: "Basque",
    LANGUE_FA: "Persian",
    LANGUE_FF: "Fulah",
    LANGUE_FI: "Finnish",
    LANGUE_FJ: "Fijian",
    LANGUE_FO: "Faroese",
    LANGUE_FR: "French",
    LANGUE_FY: "Western Frisian",
    LANGUE_GA: "Irish",
    LANGUE_GD: "Gaelic, Scottish Gaelic",
    LANGUE_GL: "Galician",
    LANGUE_GN: "Guarani",
    LANGUE_GSW: "Alsatian",
    LANGUE_GU: "Gujarati",
    LANGUE_GV: "Manx",
    LANGUE_HA: "Hausa",
    LANGUE_HE: "Hebrew",
    LANGUE_HI: "Hindi",
    LANGUE_HO: "Hiri Motu",
    LANGUE_HR: "Croatian",
    LANGUE_HT: "Haitian Creole",
    LANGUE_HU: "Hungarian",
    LANGUE_HY: "Armenian",
    LANGUE_HZ: "Herero",
    LANGUE_IA: "Interlingua (International Auxiliary Language Association)",
    LANGUE_ID: "Indonesian",
    LANGUE_IE: "Interlingue, Occidental",
    LANGUE_IG: "Igbo",
    LANGUE_II: "Sichuan Yi, Nuosu",
    LANGUE_IK: "Inupiaq",
    LANGUE_IO: "Ido",
    LANGUE_IS: "Icelandic",
    LANGUE_IT: "Italian",
    LANGUE_IU: "Inuktitut",
    LANGUE_JA: "Japanese",
    LANGUE_JV: "Javanese",
    LANGUE_KA: "Georgian",
    LANGUE_KG: "Kongo",
    LANGUE_KI: "Kikuyu, Gikuyu",
    LANGUE_KJ: "Kuanyama, Kwanyama",
    LANGUE_KK: "Kazakh",
    LANGUE_KL: "Kalaallisut, Greenlandic",
    LANGUE_KM: "Central Khmer",
    LANGUE_KN: "Kannada",
    LANGUE_KO: "Korean",
    LANGUE_KR: "Kanuri",
    LANGUE_KS: "Kashmiri",
    LANGUE_KU: "Kurdish",
    LANGUE_KV: "Komi",
    LANGUE_KW: "Cornish",
    LANGUE_KY: "Kirghiz, Kyrgyz",
    LANGUE_LA: "Latin",
    LANGUE_LB: "Luxembourgish, Letzeburgesch",
    LANGUE_LG: "Ganda",
    LANGUE_LI: "Limburgan, Limburger, Limburgish",
    LANGUE_LN: "Lingala",
    LANGUE_LO: "Lao",
    LANGUE_LSF: "French sign language",
    LANGUE_LT: "Lithuanian",
    LANGUE_LU: "Luba-Katanga",
    LANGUE_LV: "Latvian",
    LANGUE_MG: "Malagasy",
    LANGUE_MH: "Marshallese",
    LANGUE_MI: "Maori",
    LANGUE_MK: "Macedonian",
    LANGUE_ML: "Malayalam",
    LANGUE_MN: "Mongolian",
    LANGUE_MR: "Marathi",
    LANGUE_MS: "Malay",
    LANGUE_MT: "Maltese",
    LANGUE_MY: "Burmese",
    LANGUE_NA: "Nauru",
    LANGUE_NB: "Norwegian Bokmål",
    LANGUE_ND: "North Ndebele",
    LANGUE_NE: "Nepali",
    LANGUE_NG: "Ndonga",
    LANGUE_NL: "Dutch, Flemish",
    LANGUE_NN: "Norwegian Nynorsk",
    LANGUE_NO: "Norwegian",
    LANGUE_NR: "South Ndebele",
    LANGUE_NV: "Navajo, Navaho",
    LANGUE_NY: "Chichewa, Chewa, Nyanja",
    LANGUE_OC: "Occitan",
    LANGUE_OJ: "Ojibwa",
    LANGUE_OM: "Oromo",
    LANGUE_OR: "Oriya",
    LANGUE_OS: "Ossetian, Ossetic",
    LANGUE_PA: "Punjabi, Panjabi",
    LANGUE_PAC: "Pashto, Pushto",
    LANGUE_PE: "Peul",
    LANGUE_PI: "Pali",
    LANGUE_PL: "Polish",
    LANGUE_PS: "Pashto, Pushto",
    LANGUE_PT: "Portuguese",
    LANGUE_QU: "Quechua",
    LANGUE_RCF: "Reunion Creole",
    LANGUE_RM: "Romansh",
    LANGUE_RN: "Rundi",
    LANGUE_RO: "Romanian, Moldavian, Moldovan",
    LANGUE_ROM: "Romani",
    LANGUE_RU: "Russian",
    LANGUE_RW: "Kinyarwanda",
    LANGUE_SA: "Sanskrit",
    LANGUE_SC: "Sardinian",
    LANGUE_SD: "Sindhi",
    LANGUE_SE: "Northern Sami",
    LANGUE_SG: "Sango",
    LANGUE_SI: "Sinhala, Sinhalese",
    LANGUE_SK: "Slovak",
    LANGUE_SL: "Slovenian",
    LANGUE_SM: "Samoan",
    LANGUE_SN: "Shona",
    LANGUE_SO: "Somali",
    LANGUE_SON: "Sonike",
    LANGUE_SQ: "Albanian",
    LANGUE_SR: "Serbian",
    LANGUE_SS: "Swati",
    LANGUE_ST: "Southern Sotho",
    LANGUE_SU: "Sundanese",
    LANGUE_SV: "Swedish",
    LANGUE_SW: "Swahili",
    LANGUE_SWB: "Maore Comorian",
    LANGUE_TA: "Tamil",
    LANGUE_TE: "Telugu",
    LANGUE_TG: "Tajik",
    LANGUE_TH: "Thai",
    LANGUE_TI: "Tigrinya",
    LANGUE_TK: "Turkmen",
    LANGUE_TL: "Tagalog",
    LANGUE_TN: "Tswana",
    LANGUE_TO: "Tonga (Tonga Islands)",
    LANGUE_TR: "Turkish",
    LANGUE_TS: "Tsonga",
    LANGUE_TT: "Tatar",
    LANGUE_TW: "Twi",
    LANGUE_TY: "Tahitian",
    LANGUE_UG: "Uighur, Uyghur",
    LANGUE_UK: "Ukrainian",
    LANGUE_UR: "Urdu",
    LANGUE_UZ: "Uzbek",
    LANGUE_VE: "Venda",
    LANGUE_VI: "Vietnamese",
    LANGUE_VO: "Volapük",
    LANGUE_WA: "Walloon",
    LANGUE_WO: "Wolof",
    LANGUE_XH: "Xhosa",
    LANGUE_YI: "Yiddish",
    LANGUE_YO: "Yoruba",
    LANGUE_ZA: "Zhuang, Chuang",
    LANGUE_ZH: "Chinese",
    LANGUE_ZU: "Zulu",
  },
  es: {
    LANGUE_AA: "afar",
    LANGUE_AB: "abjasio (o abjasiano)",
    LANGUE_AE: "avéstico",
    LANGUE_AF: "afrikáans",
    LANGUE_AK: "akano",
    LANGUE_AM: "amhárico",
    LANGUE_AN: "aragonés",
    LANGUE_AR: "árabe",
    LANGUE_AS: "asamés",
    LANGUE_AV: "avar (o ávaro)",
    LANGUE_AY: "aimara",
    LANGUE_AZ: "azerí",
    LANGUE_BA: "baskir",
    LANGUE_BE: "bielorruso",
    LANGUE_BG: "búlgaro",
    LANGUE_BH: "bhoyapurí",
    LANGUE_BI: "bislama",
    LANGUE_BM: "bambara",
    LANGUE_BN: "bengalí",
    LANGUE_BO: "tibetano",
    LANGUE_BR: "bretón",
    LANGUE_BRC: "bereber Chleuh",
    LANGUE_BRK: "kabyle",
    LANGUE_BS: "bosnio",
    LANGUE_CA: "catalán",
    LANGUE_CE: "checheno",
    LANGUE_CH: "chamorro",
    LANGUE_CO: "corso",
    LANGUE_CR: "cree",
    LANGUE_CS: "checo",
    LANGUE_CU: "eslavo eclesiástico antiguo",
    LANGUE_CV: "chuvasio",
    LANGUE_CY: "galés",
    LANGUE_DA: "danés",
    LANGUE_DAR: "dari",
    LANGUE_DE: "alemán",
    LANGUE_DV: "maldivo (o dhivehi)",
    LANGUE_DZ: "dzongkha",
    LANGUE_EE: "ewé",
    LANGUE_EL: "griego (moderno)",
    LANGUE_EN: "inglés",
    LANGUE_EO: "esperanto",
    LANGUE_ES: "español (o castellano)",
    LANGUE_ET: "estonio",
    LANGUE_EUS: "euskera",
    LANGUE_FA: "persa",
    LANGUE_FF: "fula",
    LANGUE_FI: "finés (o finlandés)",
    LANGUE_FJ: "fiyiano (o fiyi)",
    LANGUE_FO: "feroés",
    LANGUE_FR: "francés",
    LANGUE_FY: "frisón (o frisio)",
    LANGUE_GA: "irlandés (o gaélico)",
    LANGUE_GD: "gaélico escocés",
    LANGUE_GL: "gallego",
    LANGUE_GN: "guaraní",
    LANGUE_GSW: "Alsaciano",
    LANGUE_GU: "guyaratí (o gujaratí)",
    LANGUE_GV: "manés (gaélico manés o de Isla de Man)",
    LANGUE_HA: "hausa",
    LANGUE_HE: "hebreo",
    LANGUE_HI: "hindi (o hindú)",
    LANGUE_HO: "hiri motu",
    LANGUE_HR: "croata",
    LANGUE_HT: "haitiano",
    LANGUE_HU: "húngaro",
    LANGUE_HY: "armenio",
    LANGUE_HZ: "herero",
    LANGUE_IA: "interlingua",
    LANGUE_ID: "indonesio",
    LANGUE_IE: "occidental",
    LANGUE_IG: "igbo",
    LANGUE_II: "yi de Sichuán",
    LANGUE_IK: "iñupiaq",
    LANGUE_IO: "ido",
    LANGUE_IS: "islandés",
    LANGUE_IT: "italiano",
    LANGUE_IU: "inuktitut (o inuit)",
    LANGUE_JA: "japonés",
    LANGUE_JV: "javanés",
    LANGUE_KA: "georgiano",
    LANGUE_KG: "kongo (o kikongo)",
    LANGUE_KI: "kikuyu",
    LANGUE_KJ: "kuanyama",
    LANGUE_KK: "kazajo (o kazajio)",
    LANGUE_KL: "groenlandés (o kalaallisut)",
    LANGUE_KM: "camboyano (o jemer)",
    LANGUE_KN: "canarés",
    LANGUE_KO: "coreano",
    LANGUE_KR: "kanuri",
    LANGUE_KS: "cachemiro (o cachemir)",
    LANGUE_KU: "kurdo",
    LANGUE_KV: "komi",
    LANGUE_KW: "córnico",
    LANGUE_KY: "kirguís",
    LANGUE_LA: "latín",
    LANGUE_LB: "luxemburgués",
    LANGUE_LG: "luganda",
    LANGUE_LI: "limburgués",
    LANGUE_LN: "lingala",
    LANGUE_LO: "lao",
    LANGUE_LSF: "lengua de señas francesa",
    LANGUE_LT: "lituano",
    LANGUE_LU: "luba-katanga (o chiluba)",
    LANGUE_LV: "letón",
    LANGUE_MG: "Malgache (o malagasy)",
    LANGUE_MH: "marshalés",
    LANGUE_MI: "maorí",
    LANGUE_MK: "macedonio",
    LANGUE_ML: "malayalam",
    LANGUE_MN: "mongol",
    LANGUE_MR: "maratí",
    LANGUE_MS: "malayo",
    LANGUE_MT: "maltés",
    LANGUE_MY: "birmano",
    LANGUE_NA: "nauruano",
    LANGUE_NB: "noruego bokmål",
    LANGUE_ND: "ndebele del norte",
    LANGUE_NE: "nepalí",
    LANGUE_NG: "ndonga",
    LANGUE_NL: "neerlandés (u holandés)",
    LANGUE_NN: "nynorsk",
    LANGUE_NO: "noruego",
    LANGUE_NR: "ndebele del sur",
    LANGUE_NV: "navajo",
    LANGUE_NY: "chichewa",
    LANGUE_OC: "occitano",
    LANGUE_OJ: "ojibwa",
    LANGUE_OM: "oromo",
    LANGUE_OR: "oriya",
    LANGUE_OS: "osético (u osetio, u oseta)",
    LANGUE_PA: "panyabí (o penyabi)",
    LANGUE_PAC: "pastú (o pastún, o pashto)",
    LANGUE_PE: "peul",
    LANGUE_PI: "pali",
    LANGUE_PL: "polaco",
    LANGUE_PS: "pastú (o pastún, o pashto)",
    LANGUE_PT: "portugués",
    LANGUE_QU: "quechua",
    LANGUE_RCF: "Criollo de reunión",
    LANGUE_RM: "romanche",
    LANGUE_RN: "kirundi",
    LANGUE_RO: "rumano",
    LANGUE_ROM: "Romani",
    LANGUE_RU: "ruso",
    LANGUE_RW: "ruandés (o kiñaruanda)",
    LANGUE_SA: "sánscrito",
    LANGUE_SC: "sardo",
    LANGUE_SD: "sindhi",
    LANGUE_SE: "sami septentrional",
    LANGUE_SG: "sango",
    LANGUE_SI: "cingalés",
    LANGUE_SK: "eslovaco",
    LANGUE_SL: "esloveno",
    LANGUE_SM: "samoano",
    LANGUE_SN: "shona",
    LANGUE_SO: "somalí",
    LANGUE_SON: "Sonike",
    LANGUE_SQ: "albanés",
    LANGUE_SR: "serbio",
    LANGUE_SS: "suazi (o swati, o siSwati)",
    LANGUE_ST: "sesotho",
    LANGUE_SU: "sundanés (o sondanés)",
    LANGUE_SV: "sueco",
    LANGUE_SW: "suajili",
    LANGUE_SWB: "Mahorés",
    LANGUE_TA: "tamil",
    LANGUE_TE: "télugu",
    LANGUE_TG: "tayiko",
    LANGUE_TH: "tailandés",
    LANGUE_TI: "tigriña",
    LANGUE_TK: "turcomano",
    LANGUE_TL: "tagalo",
    LANGUE_TN: "setsuana",
    LANGUE_TO: "tongano",
    LANGUE_TR: "turco",
    LANGUE_TS: "tsonga",
    LANGUE_TT: "tártaro",
    LANGUE_TW: "twi",
    LANGUE_TY: "tahitiano",
    LANGUE_UG: "uigur",
    LANGUE_UK: "ucraniano",
    LANGUE_UR: "urdu",
    LANGUE_UZ: "uzbeko",
    LANGUE_VE: "venda",
    LANGUE_VI: "vietnamita",
    LANGUE_VO: "volapük",
    LANGUE_WA: "valón",
    LANGUE_WO: "wolof",
    LANGUE_XH: "xhosa",
    LANGUE_YI: "yídish (o yidis, o yiddish)",
    LANGUE_YO: "yoruba",
    LANGUE_ZA: "chuan (o chuang, o zhuang)",
    LANGUE_ZH: "chino",
    LANGUE_ZU: "zulú",
  },
  fa: {
    LANGUE_AA: "آفر",
    LANGUE_AB: "آبخاز",
    LANGUE_AE: "اوستیک",
    LANGUE_AF: "آفریقایی",
    LANGUE_AK: "آکان",
    LANGUE_AM: "آمهری",
    LANGUE_AN: "آراگون",
    LANGUE_AR: "عرب",
    LANGUE_AS: "آسامی",
    LANGUE_AV: "آوار",
    LANGUE_AY: "آیمارا",
    LANGUE_AZ: "آذری",
    LANGUE_BA: "باشقیر",
    LANGUE_BE: "بلاروسی",
    LANGUE_BG: "بلغاری",
    LANGUE_BH: "بیهاری",
    LANGUE_BI: "بیکلامار",
    LANGUE_BM: "بامبارا",
    LANGUE_BN: "بنگالی",
    LANGUE_BO: "تبتی",
    LANGUE_BR: "برتون",
    LANGUE_BRC: "بربر چلوه",
    LANGUE_BRK: "کابل",
    LANGUE_BS: "بوسنیایی",
    LANGUE_CA: "کاتالان",
    LANGUE_CE: "چچنی",
    LANGUE_CH: "چامورو",
    LANGUE_CO: "کورسیکا",
    LANGUE_CR: "فریاد",
    LANGUE_CS: "کشور چک",
    LANGUE_CU: "اسلاوی قدیمی",
    LANGUE_CV: "چوواش",
    LANGUE_CY: "ولز",
    LANGUE_DA: "دانمارکی",
    LANGUE_DAR: "دری",
    LANGUE_DE: "آلمانی",
    LANGUE_DV: "مالدیو",
    LANGUE_DZ: "Dzongkha",
    LANGUE_EE: "میش",
    LANGUE_EL: "یونانی مدرن",
    LANGUE_EN: "انگلیسی",
    LANGUE_EO: "اسپرانتو",
    LANGUE_ES: "اسپانیایی",
    LANGUE_ET: "استونیایی",
    LANGUE_EUS: "باسکی",
    LANGUE_FA: "فارسی",
    LANGUE_FF: "پل",
    LANGUE_FI: "فنلاندی",
    LANGUE_FJ: "فیجیایی",
    LANGUE_FO: "فاروئی",
    LANGUE_FR: "فرانسوی",
    LANGUE_FY: "فریزی غربی",
    LANGUE_GA: "ایرلندی",
    LANGUE_GD: "اسکاتلندی",
    LANGUE_GL: "گالیسیایی",
    LANGUE_GN: "گوارانی",
    LANGUE_GSW: "آلزاس",
    LANGUE_GU: "گجراتی",
    LANGUE_GV: "مانوا",
    LANGUE_HA: "هاوسا",
    LANGUE_HE: "عبری",
    LANGUE_HI: "هندی",
    LANGUE_HO: "هیری موتو",
    LANGUE_HR: "کروات",
    LANGUE_HT: "کریول هائیتی",
    LANGUE_HU: "مجارستانی",
    LANGUE_HY: "ارمنی",
    LANGUE_HZ: "هررو",
    LANGUE_IA: "سرخپوستی",
    LANGUE_ID: "اندونزیایی",
    LANGUE_IE: "غربی",
    LANGUE_IG: "ایگبو",
    LANGUE_II: "یی",
    LANGUE_IK: "اینوپیاک",
    LANGUE_IO: "انجام میدهم",
    LANGUE_IS: "ایسلندی",
    LANGUE_IT: "ایتالیایی",
    LANGUE_IU: "اینوکتیتوت",
    LANGUE_JA: "ژاپنی",
    LANGUE_JV: "جاوه ای",
    LANGUE_KA: "گرجی",
    LANGUE_KG: "کیکونگو",
    LANGUE_KI: "کیکویو",
    LANGUE_KJ: "کوانیاما",
    LANGUE_KK: "قزاقی",
    LANGUE_KL: "گرینلند",
    LANGUE_KM: "خمر",
    LANGUE_KN: "کانادا",
    LANGUE_KO: "کره ای",
    LANGUE_KR: "کانوری",
    LANGUE_KS: "کشمیری",
    LANGUE_KU: "کردی",
    LANGUE_KV: "کومی",
    LANGUE_KW: "کورنیش",
    LANGUE_KY: "قرقیزی",
    LANGUE_LA: "لاتین",
    LANGUE_LB: "لوگزامبورگی",
    LANGUE_LG: "گاندا",
    LANGUE_LI: "لیمبورگ",
    LANGUE_LN: "لینگالا",
    LANGUE_LO: "لائوس",
    LANGUE_LSF: "زبان اشاره فرانسوی",
    LANGUE_LT: "لیتوانیایی",
    LANGUE_LU: "لوبا",
    LANGUE_LV: "لتونی",
    LANGUE_MG: "مالاگاسی",
    LANGUE_MH: "مارشالی",
    LANGUE_MI: "مائوری نیوزلند",
    LANGUE_MK: "مقدونی",
    LANGUE_ML: "مالایایی",
    LANGUE_MN: "مغولی",
    LANGUE_MR: "مراتی",
    LANGUE_MS: "مالایی",
    LANGUE_MT: "مالتی",
    LANGUE_MY: "برمه ای",
    LANGUE_NA: "نائورو",
    LANGUE_NB: "نروژی بوکمال",
    LANGUE_ND: "سیندبله",
    LANGUE_NE: "نپالی",
    LANGUE_NG: "ندونگا",
    LANGUE_NL: "هلندی",
    LANGUE_NN: "نینورسک نروژی",
    LANGUE_NO: "نروژی",
    LANGUE_NR: "نربله",
    LANGUE_NV: "ناواهو",
    LANGUE_NY: "چیچوا",
    LANGUE_OC: "اکسیتان",
    LANGUE_OJ: "اوجیبوه",
    LANGUE_OM: "اورومو",
    LANGUE_OR: "اوریا",
    LANGUE_OS: "اوستیایی",
    LANGUE_PA: "پنجابی",
    LANGUE_PAC: "پشتو",
    LANGUE_PE: "پل",
    LANGUE_PI: "پالی",
    LANGUE_PL: "لهستانی",
    LANGUE_PS: "پشتو",
    LANGUE_PT: "پرتغالی",
    LANGUE_QU: "کچوا",
    LANGUE_RCF: "صفحه جلسه",
    LANGUE_RM: "رومانش",
    LANGUE_RN: "کیروندی",
    LANGUE_RO: "رومانیایی",
    LANGUE_ROM: "رومی",
    LANGUE_RU: "روسی",
    LANGUE_RW: "کینیارواندا",
    LANGUE_SA: "سانسکریت",
    LANGUE_SC: "ساردینیا",
    LANGUE_SD: "سندی",
    LANGUE_SE: "سامی شمالی",
    LANGUE_SG: "سانگو",
    LANGUE_SI: "سینهالی",
    LANGUE_SK: "اسلواکی",
    LANGUE_SL: "اسلوونیایی",
    LANGUE_SM: "ساموآ",
    LANGUE_SN: "شونا",
    LANGUE_SO: "سومالی",
    LANGUE_SON: "سونیک",
    LANGUE_SQ: "آلبانیایی",
    LANGUE_SR: "صربی",
    LANGUE_SS: "سواتی",
    LANGUE_ST: "سوتو جنوبی",
    LANGUE_SU: "سوندانی",
    LANGUE_SV: "سوئدی",
    LANGUE_SW: "سواحیلی",
    LANGUE_SWB: "ماهورایی ها",
    LANGUE_TA: "تامیل",
    LANGUE_TE: "تلوگو",
    LANGUE_TG: "تاجیک",
    LANGUE_TH: "تایلندی",
    LANGUE_TI: "تیگریگنا",
    LANGUE_TK: "ترکمن",
    LANGUE_TL: "تاگالوگ",
    LANGUE_TN: "تسوانا",
    LANGUE_TO: "تونگان",
    LANGUE_TR: "ترکی",
    LANGUE_TS: "سونگا",
    LANGUE_TT: "تاتاری",
    LANGUE_TW: "توئی",
    LANGUE_TY: "تاهیتی",
    LANGUE_UG: "اویغور",
    LANGUE_UK: "اوکراینی",
    LANGUE_UR: "اردو",
    LANGUE_UZ: "ازبکستان",
    LANGUE_VE: "وندا",
    LANGUE_VI: "ویتنامی",
    LANGUE_VO: "ولاپوک",
    LANGUE_WA: "والون",
    LANGUE_WO: "وولوف",
    LANGUE_XH: "خوسا",
    LANGUE_YI: "ییدیش",
    LANGUE_YO: "یوروبا",
    LANGUE_ZA: "ژوانگ",
    LANGUE_ZH: "چینی ها",
    LANGUE_ZU: "زولو",
  },
  fr: {
    LANGUE_AA: "Afar",
    LANGUE_AB: "Abkhaze",
    LANGUE_AE: "Avestique",
    LANGUE_AF: "Afrikaans",
    LANGUE_AK: "Akan",
    LANGUE_AM: "Amharique",
    LANGUE_AN: "Aragonais",
    LANGUE_AR: "Arabe",
    LANGUE_AS: "Assamais",
    LANGUE_AV: "Avar",
    LANGUE_AY: "Aymara",
    LANGUE_AZ: "Azéri",
    LANGUE_BA: "Bachkir",
    LANGUE_BE: "Biélorusse",
    LANGUE_BG: "Bulgare",
    LANGUE_BH: "Bihari",
    LANGUE_BI: "Bichelamar",
    LANGUE_BM: "Bambara",
    LANGUE_BN: "Bengali",
    LANGUE_BO: "Tibétain",
    LANGUE_BR: "Breton",
    LANGUE_BRC: "Berbere Chleuh",
    LANGUE_BRK: "Kabyle",
    LANGUE_BS: "Bosnien",
    LANGUE_CA: "Catalan",
    LANGUE_CE: "Tchétchène",
    LANGUE_CH: "Chamorro",
    LANGUE_CO: "Corse",
    LANGUE_CR: "Cri",
    LANGUE_CS: "Tchèque",
    LANGUE_CU: "Vieux-slave",
    LANGUE_CV: "Tchouvache",
    LANGUE_CY: "Gallois",
    LANGUE_DA: "Danois",
    LANGUE_DAR: "Dari",
    LANGUE_DE: "Allemand",
    LANGUE_DV: "Maldivien",
    LANGUE_DZ: "Dzongkha",
    LANGUE_EE: "Ewe",
    LANGUE_EL: "Grec moderne",
    LANGUE_EN: "Anglais",
    LANGUE_EO: "Espéranto",
    LANGUE_ES: "Espagnol",
    LANGUE_ET: "Estonien",
    LANGUE_EUS: "Basque",
    LANGUE_FA: "Persan",
    LANGUE_FF: "Peul",
    LANGUE_FI: "Finnois",
    LANGUE_FJ: "Fidjien",
    LANGUE_FO: "Féroïen",
    LANGUE_FR: "Français",
    LANGUE_FY: "Frison occidental",
    LANGUE_GA: "Irlandais",
    LANGUE_GD: "Écossais",
    LANGUE_GL: "Galicien",
    LANGUE_GN: "Guarani",
    LANGUE_GSW: "Alsacien",
    LANGUE_GU: "Gujarati",
    LANGUE_GV: "Mannois",
    LANGUE_HA: "Haoussa",
    LANGUE_HE: "Hébreu",
    LANGUE_HI: "Hindi",
    LANGUE_HO: "Hiri motu",
    LANGUE_HR: "Croate",
    LANGUE_HT: "Créole haïtien",
    LANGUE_HU: "Hongrois",
    LANGUE_HY: "Arménien",
    LANGUE_HZ: "Héréro",
    LANGUE_IA: "Interlingua",
    LANGUE_ID: "Indonésien",
    LANGUE_IE: "Occidental",
    LANGUE_IG: "Igbo",
    LANGUE_II: "Yi",
    LANGUE_IK: "Inupiak",
    LANGUE_IO: "Ido",
    LANGUE_IS: "Islandais",
    LANGUE_IT: "Italien",
    LANGUE_IU: "Inuktitut",
    LANGUE_JA: "Japonais",
    LANGUE_JV: "Javanais",
    LANGUE_KA: "Géorgien",
    LANGUE_KG: "Kikongo",
    LANGUE_KI: "Kikuyu",
    LANGUE_KJ: "Kuanyama",
    LANGUE_KK: "Kazakh",
    LANGUE_KL: "Groenlandais",
    LANGUE_KM: "Khmer",
    LANGUE_KN: "Kannada",
    LANGUE_KO: "Coréen",
    LANGUE_KR: "Kanouri",
    LANGUE_KS: "Cachemiri",
    LANGUE_KU: "Kurde",
    LANGUE_KV: "Komi",
    LANGUE_KW: "Cornique",
    LANGUE_KY: "Kirghiz",
    LANGUE_LA: "Latin",
    LANGUE_LB: "Luxembourgeois",
    LANGUE_LG: "Ganda",
    LANGUE_LI: "Limbourgeois",
    LANGUE_LN: "Lingala",
    LANGUE_LO: "Lao",
    LANGUE_LSF: "Langue des Signes Française",
    LANGUE_LT: "Lituanien",
    LANGUE_LU: "Luba",
    LANGUE_LV: "Letton",
    LANGUE_MG: "Malgache",
    LANGUE_MH: "Marshallais",
    LANGUE_MI: "Maori de Nouvelle-Zélande",
    LANGUE_MK: "Macédonien",
    LANGUE_ML: "Malayalam",
    LANGUE_MN: "Mongol",
    LANGUE_MR: "Marathi",
    LANGUE_MS: "Malais",
    LANGUE_MT: "Maltais",
    LANGUE_MY: "Birman",
    LANGUE_NA: "Nauruan",
    LANGUE_NB: "Norvégien Bokmål",
    LANGUE_ND: "Sindebele",
    LANGUE_NE: "Népalais",
    LANGUE_NG: "Ndonga",
    LANGUE_NL: "Néerlandais",
    LANGUE_NN: "Norvégien Nynorsk",
    LANGUE_NO: "Norvégien",
    LANGUE_NR: "Nrebele",
    LANGUE_NV: "Navajo",
    LANGUE_NY: "Chichewa",
    LANGUE_OC: "Occitan",
    LANGUE_OJ: "Ojibwé",
    LANGUE_OM: "Oromo",
    LANGUE_OR: "Oriya",
    LANGUE_OS: "Ossète",
    LANGUE_PA: "Pendjabi",
    LANGUE_PAC: "Pachto",
    LANGUE_PE: "Peul",
    LANGUE_PI: "Pali",
    LANGUE_PL: "Polonais",
    LANGUE_PS: "Pachto",
    LANGUE_PT: "Portugais",
    LANGUE_QU: "Quechua",
    LANGUE_RCF: "Créole réunionnais",
    LANGUE_RM: "Romanche",
    LANGUE_RN: "Kirundi",
    LANGUE_RO: "Roumain",
    LANGUE_ROM: "Romani",
    LANGUE_RU: "Russe",
    LANGUE_RW: "Kinyarwanda",
    LANGUE_SA: "Sanskrit",
    LANGUE_SC: "Sarde",
    LANGUE_SD: "Sindhi",
    LANGUE_SE: "Same du Nord",
    LANGUE_SG: "Sango",
    LANGUE_SI: "Cingalais",
    LANGUE_SK: "Slovaque",
    LANGUE_SL: "Slovène",
    LANGUE_SM: "Samoan",
    LANGUE_SN: "Shona",
    LANGUE_SO: "Somali",
    LANGUE_SON: "Sonike",
    LANGUE_SQ: "Albanais",
    LANGUE_SR: "Serbe",
    LANGUE_SS: "Swati",
    LANGUE_ST: "Sotho du Sud",
    LANGUE_SU: "Soundanais",
    LANGUE_SV: "Suédois",
    LANGUE_SW: "Swahili",
    LANGUE_SWB: "Mahorais",
    LANGUE_TA: "Tamoul",
    LANGUE_TE: "Télougou",
    LANGUE_TG: "Tadjik",
    LANGUE_TH: "Thaï",
    LANGUE_TI: "Tigrigna",
    LANGUE_TK: "Turkmène",
    LANGUE_TL: "Tagalog",
    LANGUE_TN: "Tswana",
    LANGUE_TO: "Tongien",
    LANGUE_TR: "Turc",
    LANGUE_TS: "Tsonga",
    LANGUE_TT: "Tatar",
    LANGUE_TW: "Twi",
    LANGUE_TY: "Tahitien",
    LANGUE_UG: "Ouïghour",
    LANGUE_UK: "Ukrainien",
    LANGUE_UR: "Ourdou",
    LANGUE_UZ: "Ouzbek",
    LANGUE_VE: "Venda",
    LANGUE_VI: "Vietnamien",
    LANGUE_VO: "Volapük",
    LANGUE_WA: "Wallon",
    LANGUE_WO: "Wolof",
    LANGUE_XH: "Xhosa",
    LANGUE_YI: "Yiddish",
    LANGUE_YO: "Yoruba",
    LANGUE_ZA: "Zhuang",
    LANGUE_ZH: "Chinois",
    LANGUE_ZU: "Zoulou",
  },
  ka: {
    LANGUE_AA: "აფარი",
    LANGUE_AB: "აფხაზური",
    LANGUE_AE: "ავესტური",
    LANGUE_AF: "აფრიკაანსი",
    LANGUE_AK: "აკანი",
    LANGUE_AM: "ამჰარული",
    LANGUE_AN: "არაგონული",
    LANGUE_AR: "არაბული",
    LANGUE_AS: "ასამური",
    LANGUE_AV: "ავარ",
    LANGUE_AY: "აიმარა",
    LANGUE_AZ: "აზერბაიჯანული",
    LANGUE_BA: "ბაშკირული",
    LANGUE_BE: "ბელორუსული",
    LANGUE_BG: "ბულგარული",
    LANGUE_BH: "ბიჰარი",
    LANGUE_BI: "ბიჩელამარი",
    LANGUE_BM: "ბამბარა",
    LANGUE_BN: "ბენგალური",
    LANGUE_BO: "ტიბეტური",
    LANGUE_BR: "ბრეტონული",
    LANGUE_BRC: "ბერბერი ჩლეუჰ",
    LANGUE_BRK: "კაბილი",
    LANGUE_BS: "ბოსნიელი",
    LANGUE_CA: "კატალონიური",
    LANGUE_CE: "ჩეჩენი",
    LANGUE_CH: "ჩამორო",
    LANGUE_CO: "კორსიკა",
    LANGUE_CR: "ყვირილი",
    LANGUE_CS: "ჩეხური",
    LANGUE_CU: "ძველი სლავური",
    LANGUE_CV: "ჩუვაშური",
    LANGUE_CY: "უელსური",
    LANGUE_DA: "დანიური",
    LANGUE_DAR: "საერთო უფლება",
    LANGUE_DE: "გერმანული",
    LANGUE_DV: "მალდივიური",
    LANGUE_DZ: "ძონგხა",
    LANGUE_EE: "ევე",
    LANGUE_EL: "თანამედროვე ბერძნული",
    LANGUE_EN: "ინგლისური",
    LANGUE_EO: "ესპერანტო",
    LANGUE_ES: "ესპანური",
    LANGUE_ET: "ესტონური",
    LANGUE_EUS: "ბასკური",
    LANGUE_FA: "სპარსული",
    LANGUE_FF: "ფულანი",
    LANGUE_FI: "ფინური",
    LANGUE_FJ: "ფიჯიელი",
    LANGUE_FO: "Დაგვიკავშირდით",
    LANGUE_FR: "ფრანგული",
    LANGUE_FY: "დასავლეთფრიზიული",
    LANGUE_GA: "ირლანდიელი",
    LANGUE_GD: "შოტლანდიელი",
    LANGUE_GL: "გალისიური",
    LANGUE_GN: "გუარანი",
    LANGUE_GSW: "ელზასელი",
    LANGUE_GU: "გუჯარათი",
    LANGUE_GV: "მანქსი",
    LANGUE_HA: "ჰაუსა",
    LANGUE_HE: "ებრაული",
    LANGUE_HI: "ჰინდი",
    LANGUE_HO: "ჰირი მოტუ",
    LANGUE_HR: "ხორვატული",
    LANGUE_HT: "ჰაიტური კრეოლი",
    LANGUE_HU: "უნგრული",
    LANGUE_HY: "სომხური",
    LANGUE_HZ: "ჰერერო",
    LANGUE_IA: "ინტერლინგუალური",
    LANGUE_ID: "ინდონეზიური",
    LANGUE_IE: "ფაილის სტატუსი ახლა არის: [X39X]",
    LANGUE_IG: "იგბო",
    LANGUE_II: "იი",
    LANGUE_IK: "ინუპიაკი",
    LANGUE_IO: "Თანახმა ვარ",
    LANGUE_IS: "ისლანდიური",
    LANGUE_IT: "იტალიური",
    LANGUE_IU: "ინუკტიტუტი",
    LANGUE_JA: "იაპონელი",
    LANGUE_JV: "იავური",
    LANGUE_KA: "ქართული",
    LANGUE_KG: "კიკონგო",
    LANGUE_KI: "კიკუიუ",
    LANGUE_KJ: "კუანიამა",
    LANGUE_KK: "ყაზახური",
    LANGUE_KL: "გრენლანდიური",
    LANGUE_KM: "ქმერული",
    LANGUE_KN: "კანადა",
    LANGUE_KO: "კორეული",
    LANGUE_KR: "კანური",
    LANGUE_KS: "ქაშმირული",
    LANGUE_KU: "ქურთული",
    LANGUE_KV: "კომი",
    LANGUE_KW: "კორნული",
    LANGUE_KY: "ყირგიზული",
    LANGUE_LA: "ლათინური",
    LANGUE_LB: "ლუქსემბურგული",
    LANGUE_LG: "განდა",
    LANGUE_LI: "სერვისები რუკაზე",
    LANGUE_LN: "ლინგალა",
    LANGUE_LO: "ლაოსი",
    LANGUE_LSF: "ფრანგული ჟესტების ენა",
    LANGUE_LT: "ლიტვური",
    LANGUE_LU: "ლუბა",
    LANGUE_LV: "ლატვიური",
    LANGUE_MG: "მალაგასური",
    LANGUE_MH: "მარშალის",
    LANGUE_MI: "ახალი ზელანდიის მაორი",
    LANGUE_MK: "მაკედონელი",
    LANGUE_ML: "მალაიალამური",
    LANGUE_MN: "მონღოლური",
    LANGUE_MR: "მარათჰი",
    LANGUE_MS: "მალაიზია",
    LANGUE_MT: "მალტური",
    LANGUE_MY: "ბირმული",
    LANGUE_NA: "ნაურუანი",
    LANGUE_NB: "ნორვეგიული ბოკმალი",
    LANGUE_ND: "სინდებელი",
    LANGUE_NE: "ნეპალური",
    LANGUE_NG: "ნდონგა",
    LANGUE_NL: "ჰოლანდიური",
    LANGUE_NN: "ნორვეგიული ნინორსკი",
    LANGUE_NO: "ნორვეგიული",
    LANGUE_NR: "ნრებელე",
    LANGUE_NV: "ნავახო",
    LANGUE_NY: "ჩიჩევა",
    LANGUE_OC: "ოქსიტანური",
    LANGUE_OJ: "ოჯიბვე",
    LANGUE_OM: "ორომო",
    LANGUE_OR: "ორია",
    LANGUE_OS: "ოსი",
    LANGUE_PA: "პენჯაბური",
    LANGUE_PAC: "პუშტუ",
    LANGUE_PE: "მარშრუტი",
    LANGUE_PI: "პალი",
    LANGUE_PL: "პოლონური",
    LANGUE_PS: "პუშტუ",
    LANGUE_PT: "პორტუგალიური",
    LANGUE_QU: "კეჩუა",
    LANGUE_RCF: "Reunion Creole",
    LANGUE_RM: "რომანში",
    LANGUE_RN: "კირუნდი",
    LANGUE_RO: "რუმინული",
    LANGUE_ROM: "ბოშური",
    LANGUE_RU: "რუსული",
    LANGUE_RW: "კინიარუანდა",
    LANGUE_SA: "სანსკრიტი",
    LANGUE_SC: "სარდინიელი",
    LANGUE_SD: "სინდი",
    LANGUE_SE: "ჩრდილოეთი სამი",
    LANGUE_SG: "სანგო",
    LANGUE_SI: "სინჰალური",
    LANGUE_SK: "სლოვაკური",
    LANGUE_SL: "სლოვენური",
    LANGUE_SM: "სამოა",
    LANGUE_SN: "შონა",
    LANGUE_SO: "სომალიური",
    LANGUE_SON: "სონიკე",
    LANGUE_SQ: "ალბანური",
    LANGUE_SR: "სერბული",
    LANGUE_SS: "სვატი",
    LANGUE_ST: "სამხრეთ სოთო",
    LANGUE_SU: "სუნდური",
    LANGUE_SV: "შვედური",
    LANGUE_SW: "სუაჰილი",
    LANGUE_SWB: "მაჰორელები",
    LANGUE_TA: "ტამილური",
    LANGUE_TE: "ტელუგუ",
    LANGUE_TG: "ტაჯიკური",
    LANGUE_TH: "ტაილანდური",
    LANGUE_TI: "ტიგრინია",
    LANGUE_TK: "თურქმენი",
    LANGUE_TL: "ტაგალო",
    LANGUE_TN: "ცვანა",
    LANGUE_TO: "ტონგანის",
    LANGUE_TR: "თურქული",
    LANGUE_TS: "ცონგა",
    LANGUE_TT: "თათრული",
    LANGUE_TW: "Twi",
    LANGUE_TY: "ტაჰიტური",
    LANGUE_UG: "უიღური",
    LANGUE_UK: "უკრაინული",
    LANGUE_UR: "ურდუ",
    LANGUE_UZ: "უზბეკური",
    LANGUE_VE: "ვენდა",
    LANGUE_VI: "ვიეტნამური",
    LANGUE_VO: "ვოლაპუკი",
    LANGUE_WA: "ვალონი",
    LANGUE_WO: "ვოლოფი",
    LANGUE_XH: "ხოსა",
    LANGUE_YI: "იდიში",
    LANGUE_YO: "იორუბა",
    LANGUE_ZA: "ჟუანგი",
    LANGUE_ZH: "ჩინური",
    LANGUE_ZU: "ზულუ",
  },
  ps: {
    LANGUE_AA: "افار",
    LANGUE_AB: "ابخاز",
    LANGUE_AE: "Avestic",
    LANGUE_AF: "افریقایی",
    LANGUE_AK: "اکان",
    LANGUE_AM: "امهاریک",
    LANGUE_AN: "ارګونیز",
    LANGUE_AR: "عرب",
    LANGUE_AS: "آسامی",
    LANGUE_AV: "Avar",
    LANGUE_AY: "عماره",
    LANGUE_AZ: "ازري",
    LANGUE_BA: "بشکیر",
    LANGUE_BE: "بلاروسي",
    LANGUE_BG: "بلغاریه ایی",
    LANGUE_BH: "بهاري",
    LANGUE_BI: "بیچلامار",
    LANGUE_BM: "بامبارا",
    LANGUE_BN: "بنګالي",
    LANGUE_BO: "تبتي",
    LANGUE_BR: "بریتون",
    LANGUE_BRC: "بربر چلو",
    LANGUE_BRK: "کابلي",
    LANGUE_BS: "بوسنیایی",
    LANGUE_CA: "کتالین",
    LANGUE_CE: "چیچنیا",
    LANGUE_CH: "چمورو",
    LANGUE_CO: "کورسیکا",
    LANGUE_CR: "چیغې",
    LANGUE_CS: "چکسلواکي",
    LANGUE_CU: "زوړ سلاوی",
    LANGUE_CV: "چواش",
    LANGUE_CY: "ولش",
    LANGUE_DA: "دنمارکي",
    LANGUE_DAR: "دري",
    LANGUE_DE: "جرمني",
    LANGUE_DV: "مالدیپ",
    LANGUE_DZ: "ژونګخا",
    LANGUE_EE: "ایو",
    LANGUE_EL: "عصري یوناني",
    LANGUE_EN: "انګلیسي",
    LANGUE_EO: "ایسپیرینټو",
    LANGUE_ES: "هسپانیه ایی",
    LANGUE_ET: "ایستونین",
    LANGUE_EUS: "باسک",
    LANGUE_FA: "فارسي",
    LANGUE_FF: "پیول",
    LANGUE_FI: "فنلینډي",
    LANGUE_FJ: "فیجیان",
    LANGUE_FO: "فیروز",
    LANGUE_FR: "فرانسوي",
    LANGUE_FY: "لویدیځ فریسیان",
    LANGUE_GA: "ایرلینډي",
    LANGUE_GD: "سکاټلینډي",
    LANGUE_GL: "ګالیسي",
    LANGUE_GN: "ګوراني",
    LANGUE_GSW: "الساتیان",
    LANGUE_GU: "ګجراتي",
    LANGUE_GV: "مانویس",
    LANGUE_HA: "هوسا",
    LANGUE_HE: "عبري",
    LANGUE_HI: "هندي",
    LANGUE_HO: "هیری موټو",
    LANGUE_HR: "کرواسین",
    LANGUE_HT: "هایتی کریول",
    LANGUE_HU: "هنګري",
    LANGUE_HY: "ارمانیایی",
    LANGUE_HZ: "هیرو",
    LANGUE_IA: "انټرلینګوا",
    LANGUE_ID: "انډونیزین",
    LANGUE_IE: "لویدیځ",
    LANGUE_IG: "ایګبو",
    LANGUE_II: "یی",
    LANGUE_IK: "Inupiak",
    LANGUE_IO: "زه کوم",
    LANGUE_IS: "ایلینډیک",
    LANGUE_IT: "ایټالیوي",
    LANGUE_IU: "Inuktitut",
    LANGUE_JA: "جاپاني",
    LANGUE_JV: "جاوه ای",
    LANGUE_KA: "ګرجستاني",
    LANGUE_KG: "کیکونګو",
    LANGUE_KI: "کیکوی",
    LANGUE_KJ: "کوانیاما",
    LANGUE_KK: "قزاقي",
    LANGUE_KL: "ګرینلینډیک",
    LANGUE_KM: "خمیر",
    LANGUE_KN: "کناډا",
    LANGUE_KO: "کوریایی",
    LANGUE_KR: "کانوري",
    LANGUE_KS: "کشمیري",
    LANGUE_KU: "کرد",
    LANGUE_KV: "کومي",
    LANGUE_KW: "کورنیش",
    LANGUE_KY: "کرغیز",
    LANGUE_LA: "لاتین",
    LANGUE_LB: "لوګزامبورګي",
    LANGUE_LG: "ګندا",
    LANGUE_LI: "لیمبوریش",
    LANGUE_LN: "لينګلا",
    LANGUE_LO: "لاو",
    LANGUE_LSF: "فرانسوي لاسلیک ژبه",
    LANGUE_LT: "لیتوانیایی",
    LANGUE_LU: "لوبا",
    LANGUE_LV: "لیتوین",
    LANGUE_MG: "ملاګاسي",
    LANGUE_MH: "مارشالیز",
    LANGUE_MI: "د نیوزیلینډ ماوري",
    LANGUE_MK: "مقدوني",
    LANGUE_ML: "ملایلم",
    LANGUE_MN: "مغولي",
    LANGUE_MR: "مراټي",
    LANGUE_MS: "مالایا",
    LANGUE_MT: "مالتیس",
    LANGUE_MY: "برمایی",
    LANGUE_NA: "نوروان",
    LANGUE_NB: "ناروېژي بوکمل",
    LANGUE_ND: "سندبل",
    LANGUE_NE: "نیپالي",
    LANGUE_NG: "Ndonga",
    LANGUE_NL: "الماني",
    LANGUE_NN: "نارویژی نینورسک",
    LANGUE_NO: "نارویژي",
    LANGUE_NR: "Nrebele",
    LANGUE_NV: "ناواجو",
    LANGUE_NY: "چیچوا",
    LANGUE_OC: "اوکیتین",
    LANGUE_OJ: "Ojibwe",
    LANGUE_OM: "اورومو",
    LANGUE_OR: "اوریا",
    LANGUE_OS: "اوسیتیا",
    LANGUE_PA: "پنجابي",
    LANGUE_PAC: "پښتو",
    LANGUE_PE: "پیول",
    LANGUE_PI: "پالي",
    LANGUE_PL: "پولیش",
    LANGUE_PS: "پښتو",
    LANGUE_PT: "پورتګالي",
    LANGUE_QU: "کویچوا",
    LANGUE_RCF: "Reunion Creole",
    LANGUE_RM: "رومانش",
    LANGUE_RN: "کیروندي",
    LANGUE_RO: "رومانیایی",
    LANGUE_ROM: "روماني",
    LANGUE_RU: "روسي",
    LANGUE_RW: "کینیاروندا",
    LANGUE_SA: "سانسکریټ",
    LANGUE_SC: "سارډینیان",
    LANGUE_SD: "سندي",
    LANGUE_SE: "شمالي سامي",
    LANGUE_SG: "سانګو",
    LANGUE_SI: "سنګال",
    LANGUE_SK: "سلواکي",
    LANGUE_SL: "سلوانیایی",
    LANGUE_SM: "سامون",
    LANGUE_SN: "شونا",
    LANGUE_SO: "سومالیایی",
    LANGUE_SON: "سونیک",
    LANGUE_SQ: "البانیایی",
    LANGUE_SR: "صربي",
    LANGUE_SS: "سواتی",
    LANGUE_ST: "جنوبي سوتو",
    LANGUE_SU: "ساؤنډنیز",
    LANGUE_SV: "سویډیش یا سویډني",
    LANGUE_SW: "سواحلي",
    LANGUE_SWB: "مهوریس",
    LANGUE_TA: "تامیل",
    LANGUE_TE: "تیلوګو",
    LANGUE_TG: "تاجک",
    LANGUE_TH: "تای",
    LANGUE_TI: "Tigrigna",
    LANGUE_TK: "ترکمني",
    LANGUE_TL: "تګالوګ",
    LANGUE_TN: "سوانا",
    LANGUE_TO: "ټانګان",
    LANGUE_TR: "ترکي",
    LANGUE_TS: "سونګا",
    LANGUE_TT: "تاتار",
    LANGUE_TW: "دوه",
    LANGUE_TY: "تایتیان",
    LANGUE_UG: "اویغور",
    LANGUE_UK: "اوکرایني",
    LANGUE_UR: "اردو",
    LANGUE_UZ: "ازبکي",
    LANGUE_VE: "وینډا",
    LANGUE_VI: "ویتنامي",
    LANGUE_VO: "Volapük",
    LANGUE_WA: "والون",
    LANGUE_WO: "ولف",
    LANGUE_XH: "ژوسا",
    LANGUE_YI: "یدیش",
    LANGUE_YO: "یوروبا",
    LANGUE_ZA: "ژوانګ",
    LANGUE_ZH: "چینایی",
    LANGUE_ZU: "زولو",
  },
  ro: {
    LANGUE_AA: "De departe",
    LANGUE_AB: "abhaziană",
    LANGUE_AE: "Avestan",
    LANGUE_AF: "afrikaans",
    LANGUE_AK: "Akan",
    LANGUE_AM: "amharică",
    LANGUE_AN: "aragoneză",
    LANGUE_AR: "arab",
    LANGUE_AS: "assameză",
    LANGUE_AV: "Ava",
    LANGUE_AY: "Aymara",
    LANGUE_AZ: "azeri",
    LANGUE_BA: "Bashkir",
    LANGUE_BE: "Belarus",
    LANGUE_BG: "bulgară",
    LANGUE_BH: "Bihari",
    LANGUE_BI: "Bichelamar",
    LANGUE_BM: "Bambara",
    LANGUE_BN: "bengaleză",
    LANGUE_BO: "tibetan",
    LANGUE_BR: "Breton",
    LANGUE_BRC: "Berber Chleuh",
    LANGUE_BRK: "Kabyle",
    LANGUE_BS: "Bosniac",
    LANGUE_CA: "catalan",
    LANGUE_CE: "cecenă",
    LANGUE_CH: "Chamorro",
    LANGUE_CO: "Corsica",
    LANGUE_CR: "Strigăt",
    LANGUE_CS: "ceh",
    LANGUE_CU: "slava veche",
    LANGUE_CV: "civaș",
    LANGUE_CY: "galeză",
    LANGUE_DA: "danez",
    LANGUE_DAR: "Dari",
    LANGUE_DE: "limba germana",
    LANGUE_DV: "Maldiviană",
    LANGUE_DZ: "Dzongkha",
    LANGUE_EE: "Oaie",
    LANGUE_EL: "Greacă modernă",
    LANGUE_EN: "Engleză",
    LANGUE_EO: "esperanto",
    LANGUE_ES: "Spaniolă",
    LANGUE_ET: "estonă",
    LANGUE_EUS: "bască",
    LANGUE_FA: "persană",
    LANGUE_FF: "Fulani",
    LANGUE_FI: "finlandeză",
    LANGUE_FJ: "fijian",
    LANGUE_FO: "Feroian",
    LANGUE_FR: "Francez",
    LANGUE_FY: "Frizon occidental",
    LANGUE_GA: "irlandez",
    LANGUE_GD: "scoţian",
    LANGUE_GL: "Galician",
    LANGUE_GN: "Guarani",
    LANGUE_GSW: "alsacian",
    LANGUE_GU: "Gujarati",
    LANGUE_GV: "Manx",
    LANGUE_HA: "Hausa",
    LANGUE_HE: "ebraică",
    LANGUE_HI: "hindi",
    LANGUE_HO: "Hiri motu",
    LANGUE_HR: "croat",
    LANGUE_HT: "creola haitiană",
    LANGUE_HU: "maghiară",
    LANGUE_HY: "armean",
    LANGUE_HZ: "Herero",
    LANGUE_IA: "Interlingua",
    LANGUE_ID: "indoneziană",
    LANGUE_IE: "Starea înregistrării este acum: [X39X]",
    LANGUE_IG: "Igbo",
    LANGUE_II: "Yi",
    LANGUE_IK: "Inupiak",
    LANGUE_IO: "Fac",
    LANGUE_IS: "islandez",
    LANGUE_IT: "Italiană",
    LANGUE_IU: "Inuktitut",
    LANGUE_JA: "japonez",
    LANGUE_JV: "javaneză",
    LANGUE_KA: "georgian",
    LANGUE_KG: "Kikongo",
    LANGUE_KI: "Kikuyu",
    LANGUE_KJ: "Kuanyama",
    LANGUE_KK: "kazah",
    LANGUE_KL: "groenlandeză",
    LANGUE_KM: "Khmer",
    LANGUE_KN: "Kannada",
    LANGUE_KO: "coreeană",
    LANGUE_KR: "Kanouri",
    LANGUE_KS: "Kashmir",
    LANGUE_KU: "kurdă",
    LANGUE_KV: "Komi",
    LANGUE_KW: "Cornish",
    LANGUE_KY: "kirghiz",
    LANGUE_LA: "latin",
    LANGUE_LB: "luxemburghez",
    LANGUE_LG: "Ganda",
    LANGUE_LI: "Servicii mapate",
    LANGUE_LN: "Lingala",
    LANGUE_LO: "Lao",
    LANGUE_LSF: "Limba semnelor franceze",
    LANGUE_LT: "lituanian",
    LANGUE_LU: "Luba",
    LANGUE_LV: "letonă",
    LANGUE_MG: "malgaș",
    LANGUE_MH: "Marshallese",
    LANGUE_MI: "Maori din Noua Zeelandă",
    LANGUE_MK: "macedonean",
    LANGUE_ML: "Malayalam",
    LANGUE_MN: "mongol",
    LANGUE_MR: "marathi",
    LANGUE_MS: "Malaeză",
    LANGUE_MT: "malteză",
    LANGUE_MY: "birmanez",
    LANGUE_NA: "nauruan",
    LANGUE_NB: "norvegiana bokmål",
    LANGUE_ND: "Sindebele",
    LANGUE_NE: "nepalez",
    LANGUE_NG: "Ndonga",
    LANGUE_NL: "olandeză",
    LANGUE_NN: "Nynorsk norvegian",
    LANGUE_NO: "norvegian",
    LANGUE_NR: "Nrebele",
    LANGUE_NV: "Navajo",
    LANGUE_NY: "Chichewa",
    LANGUE_OC: "occitană",
    LANGUE_OJ: "Ojibwe",
    LANGUE_OM: "Oromo",
    LANGUE_OR: "Oriya",
    LANGUE_OS: "osetă",
    LANGUE_PA: "punjabi",
    LANGUE_PAC: "Pashto",
    LANGUE_PE: "Itinerar",
    LANGUE_PI: "Pali",
    LANGUE_PL: "Lustrui",
    LANGUE_PS: "Pashto",
    LANGUE_PT: "portugheză",
    LANGUE_QU: "quechua",
    LANGUE_RCF: "Reunion Creole",
    LANGUE_RM: "romanșă",
    LANGUE_RN: "Kirundi",
    LANGUE_RO: "Română",
    LANGUE_ROM: "romani",
    LANGUE_RU: "Rusă",
    LANGUE_RW: "kinyarwanda",
    LANGUE_SA: "sanscrit",
    LANGUE_SC: "sardiniană",
    LANGUE_SD: "Sindhi",
    LANGUE_SE: "Sami de Nord",
    LANGUE_SG: "Sango",
    LANGUE_SI: "Sinhalez",
    LANGUE_SK: "slovacă",
    LANGUE_SL: "slovenă",
    LANGUE_SM: "Samoan",
    LANGUE_SN: "Shona",
    LANGUE_SO: "somalez",
    LANGUE_SON: "sonike",
    LANGUE_SQ: "albanez",
    LANGUE_SR: "sârb",
    LANGUE_SS: "Swati",
    LANGUE_ST: "Sotho de Sud",
    LANGUE_SU: "Sundaneza",
    LANGUE_SV: "suedez",
    LANGUE_SW: "Swahili",
    LANGUE_SWB: "Mahorais",
    LANGUE_TA: "tamil",
    LANGUE_TE: "Telugu",
    LANGUE_TG: "Tadjik",
    LANGUE_TH: "thailandez",
    LANGUE_TI: "Tigrigna",
    LANGUE_TK: "turkmeni",
    LANGUE_TL: "tagalog",
    LANGUE_TN: "Tswana",
    LANGUE_TO: "Tongan",
    LANGUE_TR: "turc",
    LANGUE_TS: "Tsonga",
    LANGUE_TT: "tătar",
    LANGUE_TW: "Twi",
    LANGUE_TY: "tahitian",
    LANGUE_UG: "uigură",
    LANGUE_UK: "ucrainean",
    LANGUE_UR: "Urdu",
    LANGUE_UZ: "uzbec",
    LANGUE_VE: "Venda",
    LANGUE_VI: "vietnamez",
    LANGUE_VO: "Volapük",
    LANGUE_WA: "valon",
    LANGUE_WO: "wolof",
    LANGUE_XH: "Xhosa",
    LANGUE_YI: "idiş",
    LANGUE_YO: "Yoruba",
    LANGUE_ZA: "Zhuang",
    LANGUE_ZH: "chinez",
    LANGUE_ZU: "Zulu",
  },
  ru: {
    LANGUE_AA: "Афар",
    LANGUE_AB: "Абхазский",
    LANGUE_AE: "Авестийский",
    LANGUE_AF: "африкаанс",
    LANGUE_AK: "Акан",
    LANGUE_AM: "Амхарский",
    LANGUE_AN: "Арагонский",
    LANGUE_AR: "арабский",
    LANGUE_AS: "Ассамский",
    LANGUE_AV: "Аварский",
    LANGUE_AY: "аймара",
    LANGUE_AZ: "Азербайджанский",
    LANGUE_BA: "Башкирский",
    LANGUE_BE: "Белорусский",
    LANGUE_BG: "болгарский",
    LANGUE_BH: "Языки бихари",
    LANGUE_BI: "Бислама",
    LANGUE_BM: "Бамбара",
    LANGUE_BN: "Бенгальский",
    LANGUE_BO: "тибетский",
    LANGUE_BR: "Бретонский",
    LANGUE_BRC: "Берберский хлеух",
    LANGUE_BRK: "Kabyle",
    LANGUE_BS: "Боснийский",
    LANGUE_CA: "Каталонский",
    LANGUE_CE: "Чеченский",
    LANGUE_CH: "Чаморро",
    LANGUE_CO: "Корсиканский",
    LANGUE_CR: "Кри",
    LANGUE_CS: "Чешский",
    LANGUE_CU:
      "Церковнославянский, старославянский, церковнославянский, староболгарский, старославянский",
    LANGUE_CV: "Чувашский",
    LANGUE_CY: "валлийский",
    LANGUE_DA: "Датский",
    LANGUE_DAR: "Дари",
    LANGUE_DE: "Немецкий",
    LANGUE_DV: "Дивехи, Дивехи, Мальдивы",
    LANGUE_DZ: "Дзонгка",
    LANGUE_EE: "Ewe",
    LANGUE_EL: "Греческий, современный (1453–)",
    LANGUE_EN: "английский",
    LANGUE_EO: "эсперанто",
    LANGUE_ES: "Испанский, кастильский",
    LANGUE_ET: "эстонский",
    LANGUE_EUS: "Баскский",
    LANGUE_FA: "Персидский",
    LANGUE_FF: "Фулах",
    LANGUE_FI: "Финский",
    LANGUE_FJ: "Фиджийский",
    LANGUE_FO: "Фарерские острова",
    LANGUE_FR: "Французский",
    LANGUE_FY: "Западно-фризский",
    LANGUE_GA: "Ирландский",
    LANGUE_GD: "Гэльский, шотландский гэльский",
    LANGUE_GL: "Галицкий",
    LANGUE_GN: "Гуарани",
    LANGUE_GSW: "Эльзасский",
    LANGUE_GU: "Гуджарати",
    LANGUE_GV: "Манкс",
    LANGUE_HA: "Хауса",
    LANGUE_HE: "иврит",
    LANGUE_HI: "хинди",
    LANGUE_HO: "Хири Моту",
    LANGUE_HR: "хорватский",
    LANGUE_HT: "Гаитянский, гаитянский креольский",
    LANGUE_HU: "венгерский язык",
    LANGUE_HY: "Армянский",
    LANGUE_HZ: "Гереро",
    LANGUE_IA: "Интерлингва (Международная ассоциация вспомогательных языков)",
    LANGUE_ID: "индонезийский",
    LANGUE_IE: "Интерлингв, западный",
    LANGUE_IG: "Игбо",
    LANGUE_II: "Сычуань И, Нуосу",
    LANGUE_IK: "Инупиак",
    LANGUE_IO: "Я делаю",
    LANGUE_IS: "исландский",
    LANGUE_IT: "Итальянский",
    LANGUE_IU: "Инуктитут",
    LANGUE_JA: "Японский",
    LANGUE_JV: "Яванский",
    LANGUE_KA: "Грузинский",
    LANGUE_KG: "Конго",
    LANGUE_KI: "Кикую, Гикую",
    LANGUE_KJ: "Куаньяма, Кваньяма",
    LANGUE_KK: "Казахский",
    LANGUE_KL: "Калааллисут, гренландский",
    LANGUE_KM: "Центральный кхмерский",
    LANGUE_KN: "Каннада",
    LANGUE_KO: "Корейский",
    LANGUE_KR: "Канури",
    LANGUE_KS: "Кашмири",
    LANGUE_KU: "Курдский",
    LANGUE_KV: "Коми",
    LANGUE_KW: "Корнуолл",
    LANGUE_KY: "Киргизский, киргизский",
    LANGUE_LA: "латинский",
    LANGUE_LB: "Люксембургский, Letzeburgesch",
    LANGUE_LG: "Ганда",
    LANGUE_LI: "Лимбургский, лимбургский, лимбургский",
    LANGUE_LN: "Лингала",
    LANGUE_LO: "Лаосский",
    LANGUE_LSF: "Французский язык жестов",
    LANGUE_LT: "Литовский",
    LANGUE_LU: "Люба-Катанга",
    LANGUE_LV: "латышский язык",
    LANGUE_MG: "Малагасийский",
    LANGUE_MH: "Маршалльский",
    LANGUE_MI: "маори",
    LANGUE_MK: "македонский",
    LANGUE_ML: "Малаялам",
    LANGUE_MN: "Монгольский",
    LANGUE_MR: "Маратхи",
    LANGUE_MS: "малайский",
    LANGUE_MT: "Мальтийский",
    LANGUE_MY: "Бирманский",
    LANGUE_NA: "Науру",
    LANGUE_NB: "Норвежский букмол",
    LANGUE_ND: "Северный Ндебеле",
    LANGUE_NE: "Непальский",
    LANGUE_NG: "Ндонга",
    LANGUE_NL: "Голландский, фламандский",
    LANGUE_NN: "Норвежский нюнорск",
    LANGUE_NO: "норвежский язык",
    LANGUE_NR: "Южный Ндебеле",
    LANGUE_NV: "Навахо (навахо)",
    LANGUE_NY: "Чичева, Чева, Ньянджа",
    LANGUE_OC: "Окситанский",
    LANGUE_OJ: "Оджибва",
    LANGUE_OM: "Оромо",
    LANGUE_OR: "Ория",
    LANGUE_OS: "Осетинский, Осетинский",
    LANGUE_PA: "Панджаби (панджаби)",
    LANGUE_PAC: "Пушту, пушту",
    LANGUE_PE: "Peul",
    LANGUE_PI: "пали",
    LANGUE_PL: "Польский",
    LANGUE_PS: "Пушту, пушту",
    LANGUE_PT: "португальский",
    LANGUE_QU: "кечуа",
    LANGUE_RCF: "Реюньон креольский",
    LANGUE_RM: "Ретороманский",
    LANGUE_RN: "Рунди",
    LANGUE_RO: "Румынский, молдавский, молдавский",
    LANGUE_ROM: "цыгане",
    LANGUE_RU: "русский",
    LANGUE_RW: "Киньяруанда",
    LANGUE_SA: "санскрит",
    LANGUE_SC: "Сардинский",
    LANGUE_SD: "Синдхи",
    LANGUE_SE: "Северные саамы",
    LANGUE_SG: "Санго",
    LANGUE_SI: "Сингальский, сингальский",
    LANGUE_SK: "словацкий",
    LANGUE_SL: "словенский",
    LANGUE_SM: "Самоанский",
    LANGUE_SN: "Шона",
    LANGUE_SO: "Сомалийский",
    LANGUE_SON: "Sonike",
    LANGUE_SQ: "албанский",
    LANGUE_SR: "сербский",
    LANGUE_SS: "Свати",
    LANGUE_ST: "Южный сото",
    LANGUE_SU: "Суданский",
    LANGUE_SV: "Шведский",
    LANGUE_SW: "суахили",
    LANGUE_SWB: "махораи",
    LANGUE_TA: "Тамильский",
    LANGUE_TE: "телугу",
    LANGUE_TG: "Таджикский",
    LANGUE_TH: "Тайский",
    LANGUE_TI: "Тигринья",
    LANGUE_TK: "туркменский",
    LANGUE_TL: "Тагальский",
    LANGUE_TN: "Тсвана",
    LANGUE_TO: "Тонга (острова Тонга)",
    LANGUE_TR: "турецкий",
    LANGUE_TS: "Тсонга",
    LANGUE_TT: "Татарский",
    LANGUE_TW: "Тви",
    LANGUE_TY: "Таитянский",
    LANGUE_UG: "Уйгурский, уйгурский",
    LANGUE_UK: "Украинский",
    LANGUE_UR: "Урду",
    LANGUE_UZ: "Узбекский",
    LANGUE_VE: "Венда",
    LANGUE_VI: "вьетнамский",
    LANGUE_VO: "Волапюк",
    LANGUE_WA: "валлонский",
    LANGUE_WO: "Волоф",
    LANGUE_XH: "Коса",
    LANGUE_YI: "идиш",
    LANGUE_YO: "Йоруба",
    LANGUE_ZA: "Чжуан, Чжуан",
    LANGUE_ZH: "китайский язык",
    LANGUE_ZU: "Зулусский",
  },
  uk: {
    LANGUE_AA: "Далеко",
    LANGUE_AB: "абхазька",
    LANGUE_AE: "авестійський",
    LANGUE_AF: "Африкаанс",
    LANGUE_AK: "Акан",
    LANGUE_AM: "амхарська",
    LANGUE_AN: "арагонський",
    LANGUE_AR: "араб",
    LANGUE_AS: "ассамський",
    LANGUE_AV: "аварський",
    LANGUE_AY: "аймара",
    LANGUE_AZ: "азербайджанський",
    LANGUE_BA: "башкирський",
    LANGUE_BE: "білоруський",
    LANGUE_BG: "болгарський",
    LANGUE_BH: "Біхарі",
    LANGUE_BI: "Біслама",
    LANGUE_BM: "Бамбара",
    LANGUE_BN: "Бенгальська",
    LANGUE_BO: "Тибетський",
    LANGUE_BR: "Бретонський",
    LANGUE_BRC: "Бербер Chleuh",
    LANGUE_BRK: "Кабіле",
    LANGUE_BS: "Боснійська",
    LANGUE_CA: "Каталонська",
    LANGUE_CE: "Чеченський",
    LANGUE_CH: "Чаморо",
    LANGUE_CO: "Корсика",
    LANGUE_CR: "Кричати",
    LANGUE_CS: "чеський",
    LANGUE_CU: "старослов'янський",
    LANGUE_CV: "чуваська",
    LANGUE_CY: "Валлійська",
    LANGUE_DA: "датський",
    LANGUE_DAR: "Дарі",
    LANGUE_DE: "німецький",
    LANGUE_DV: "мальдівська",
    LANGUE_DZ: "Дзонгха",
    LANGUE_EE: "вівця",
    LANGUE_EL: "Новогрецька",
    LANGUE_EN: "Англійська",
    LANGUE_EO: "Есперанто",
    LANGUE_ES: "Іспанська",
    LANGUE_ET: "Естонська",
    LANGUE_EUS: "баскський",
    LANGUE_FA: "Перська",
    LANGUE_FF: "Фулані",
    LANGUE_FI: "Фінська",
    LANGUE_FJ: "фіджійський",
    LANGUE_FO: "Фарерські",
    LANGUE_FR: "французький",
    LANGUE_FY: "Західнофризька",
    LANGUE_GA: "ірландський",
    LANGUE_GD: "шотландський",
    LANGUE_GL: "Галицька",
    LANGUE_GN: "гуарані",
    LANGUE_GSW: "ельзаський",
    LANGUE_GU: "Гуджараті",
    LANGUE_GV: "Менська",
    LANGUE_HA: "Хауса",
    LANGUE_HE: "Іврит",
    LANGUE_HI: "гінді",
    LANGUE_HO: "Хірі моту",
    LANGUE_HR: "Хорватська",
    LANGUE_HT: "гаїтянська креольська",
    LANGUE_HU: "Угорська",
    LANGUE_HY: "Вірменський",
    LANGUE_HZ: "Гереро",
    LANGUE_IA: "Інтерлінгва",
    LANGUE_ID: "Індонезійська",
    LANGUE_IE: "західна",
    LANGUE_IG: "Ігбо",
    LANGUE_II: "yi",
    LANGUE_IK: "Інупіак",
    LANGUE_IO: "Я згоден",
    LANGUE_IS: "Ісландська",
    LANGUE_IT: "Італійська",
    LANGUE_IU: "Інуктитут",
    LANGUE_JA: "японські",
    LANGUE_JV: "яванський",
    LANGUE_KA: "грузинський",
    LANGUE_KG: "Конго",
    LANGUE_KI: "Кікую",
    LANGUE_KJ: "Куаньяма",
    LANGUE_KK: "Казахська",
    LANGUE_KL: "гренландський",
    LANGUE_KM: "кхмерська",
    LANGUE_KN: "Каннада",
    LANGUE_KO: "корейський",
    LANGUE_KR: "Канурі",
    LANGUE_KS: "кашмірський",
    LANGUE_KU: "курдська",
    LANGUE_KV: "комі",
    LANGUE_KW: "Корніш",
    LANGUE_KY: "киргизька",
    LANGUE_LA: "латинська",
    LANGUE_LB: "Люксембурзька",
    LANGUE_LG: "Ганда",
    LANGUE_LI: "лімбурзький",
    LANGUE_LN: "Лінгала",
    LANGUE_LO: "Лаос",
    LANGUE_LSF: "Французька мова жестів",
    LANGUE_LT: "Литовська",
    LANGUE_LU: "Люба",
    LANGUE_LV: "Латвійська",
    LANGUE_MG: "Малагасійська",
    LANGUE_MH: "маршальський",
    LANGUE_MI: "новозеландські маорі",
    LANGUE_MK: "македонський",
    LANGUE_ML: "Малаялам",
    LANGUE_MN: "Монгольська",
    LANGUE_MR: "Маратхі",
    LANGUE_MS: "Малайська",
    LANGUE_MT: "Мальтійська",
    LANGUE_MY: "бірманський",
    LANGUE_NA: "науруанський",
    LANGUE_NB: "норвезька букмол",
    LANGUE_ND: "Сіндебеле",
    LANGUE_NE: "непальська",
    LANGUE_NG: "Ндонга",
    LANGUE_NL: "голландський",
    LANGUE_NN: "Норвезький Нюнорськ",
    LANGUE_NO: "Норвезька",
    LANGUE_NR: "Нребеле",
    LANGUE_NV: "навахо",
    LANGUE_NY: "Чічева",
    LANGUE_OC: "окситанський",
    LANGUE_OJ: "Оджібвей",
    LANGUE_OM: "Оромо",
    LANGUE_OR: "Орія",
    LANGUE_OS: "осетинський",
    LANGUE_PA: "Пенджабі",
    LANGUE_PAC: "Пушту",
    LANGUE_PE: "Фулані",
    LANGUE_PI: "палі",
    LANGUE_PL: "польський",
    LANGUE_PS: "Пушту",
    LANGUE_PT: "Португальська",
    LANGUE_QU: "кечуанська",
    LANGUE_RCF: "Реюньйон креольський",
    LANGUE_RM: "ретороманська",
    LANGUE_RN: "Кірунді",
    LANGUE_RO: "Румунська",
    LANGUE_ROM: "ромська",
    LANGUE_RU: "російський",
    LANGUE_RW: "Кіньяруанда",
    LANGUE_SA: "санскрит",
    LANGUE_SC: "Сардинська",
    LANGUE_SD: "Сінд",
    LANGUE_SE: "Північні саами",
    LANGUE_SG: "Санго",
    LANGUE_SI: "сингальський",
    LANGUE_SK: "Словацька",
    LANGUE_SL: "Словенська",
    LANGUE_SM: "самоанська",
    LANGUE_SN: "Шона",
    LANGUE_SO: "сомалійський",
    LANGUE_SON: "sonike",
    LANGUE_SQ: "албанський",
    LANGUE_SR: "Сербська",
    LANGUE_SS: "сваті",
    LANGUE_ST: "Південний Сото",
    LANGUE_SU: "суданський",
    LANGUE_SV: "шведський",
    LANGUE_SW: "Суахілі",
    LANGUE_SWB: "Магори",
    LANGUE_TA: "тамільська",
    LANGUE_TE: "Телугу",
    LANGUE_TG: "таджицька",
    LANGUE_TH: "Тайська",
    LANGUE_TI: "Тігрінья",
    LANGUE_TK: "туркменська",
    LANGUE_TL: "філіппінець",
    LANGUE_TN: "тсвана",
    LANGUE_TO: "Тонга",
    LANGUE_TR: "Турецька",
    LANGUE_TS: "Цонга",
    LANGUE_TT: "татарська",
    LANGUE_TW: "Тві",
    LANGUE_TY: "таїтянський",
    LANGUE_UG: "уйгурський",
    LANGUE_UK: "Український",
    LANGUE_UR: "Урду",
    LANGUE_UZ: "узбецька",
    LANGUE_VE: "Селла",
    LANGUE_VI: "в'єтнамська",
    LANGUE_VO: "Волапук",
    LANGUE_WA: "валлонський",
    LANGUE_WO: "Волоф",
    LANGUE_XH: "xhosa",
    LANGUE_YI: "Ідиш",
    LANGUE_YO: "Йоруба",
    LANGUE_ZA: "Чжуан",
    LANGUE_ZH: "китайський",
    LANGUE_ZU: "Зулу",
  },
};
