<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<header class="p-3 mb-5 row justify-content-center">
  <img class="col-2" src="assets/images/logo.png" />
</header>

<main class="container text-primary">
  <div class="row justify-content-center">
    <div class="col-8">
      <h1>Conditions générales d’utilisation de Soliguide</h1>
      <p>
        <em>Date de la dernière mise à jour : février 2023</em>
      </p>
      <h2>1. Qui sommes-nous ?</h2>
      <p>
        La plateforme et le widget Soliguide (« <strong>Soliguide</strong> »)
        sont édités par l’association loi 1901 Solinum dont le siège social est
        situé 153 rue David Johnston 33000 Bordeaux.
      </p>
      <p>
        Vous pouvez nous contacter à tout moment à l’adresse
        <a href="mailto:contact@solinum.org">contact@solinum.org</a>.
      </p>
      <p>
        Soliguide est hébergé par Clever Cloud situé au 3 rue de l’Allier, 44000
        Nantes, OVH situé 2 rue Kellermann, 59100 Roubaix et Scaleway situé au 8
        rue de la Ville l’Evêque, 75008 Paris, dont les serveurs sont localisés
        au sein de l’Union européenne.
      </p>
      <h2>2. Quel est l’utilité des conditions générales d’utilisation ?</h2>
      <p>
        La confiance que vous nous portez tous les jours est notre priorité. À
        ce titre, nous mettons tout en œuvre pour que vous puissiez maintenir
        cette confiance au quotidien, ce qui passe évidemment par des conditions
        générales d’utilisation (« <strong>CGU</strong> ») respectées et connues
        de tous.
      </p>
      <p>
        Les CGU, qui s’appliquent à l’intégralité des services fournis par
        Soliguide, ont justement pour objectif de vous présenter les règles que
        vous devez respecter lorsque vous l’utilisez sachant que toute
        utilisation de Soliguide implique l’adhésion sans réserve aux CGU.
      </p>
      <p>
        Vous attestez donc avoir pris connaissance des CGU préalablement à toute
        utilisation de Soliguide et avoir disposé de toutes les informations et
        compétences nécessaires pour vous assurer que les services fournis
        correspondent à vos besoins.
      </p>
      <h2>3. Quelles sont les conditions pour disposer d’un compte ?</h2>
      <p>
        Pour vous créer un compte sur Soliguide, vous devez être une personne
        physique, majeure, jouissant de la pleine capacité juridique permettant
        de vous engager au titre des CGU.
      </p>
      <p>
        Votre compte est strictement personnel. Vous vous engagez donc à fournir
        des données d’identifications requises à la création du compte de façon
        personnelle, correcte, exacte et véridique. Vous devez également nous
        informer de tout changement concernant les informations à fournir pour
        l’utilisation normale de Soliguide.
      </p>
      <p>
        Vous êtes responsable du maintien de la confidentialité de votre compte
        et de votre mot de passe ainsi que des restrictions d'accès à votre
        terminal (ex : tablette, mobile, etc.). En cas de doute sur la
        vulnérabilité de votre compte ou sur la confidentialité de vos accès,
        vous devez changer votre mot de passe et/ou contacter notre service
        support dans les plus brefs délais. À défaut, et dans la limite de ce
        qui est autorisé par la loi applicable, vous êtes responsable de toutes
        les activités qui ont été menées depuis votre compte.
      </p>
      <p>
        Nous recommandons d’utiliser un mot de passe complexe (constitué de
        chiffres, de lettres minuscules et majuscules et de signes de
        ponctuation) permettant d’augmenter la sécurité d’accès au compte et de
        changer régulièrement de mot de passe en accédant aux paramètres de
        compte.
      </p>
      <h2>4. Le widget Soliguide est-il gratuit ?</h2>
      <p>
        Le widget Soliguide est actuellement fourni à titre gratuit. Toutefois,
        nous nous réservons la possibilité de rendre payante l’utilisation du
        widget ou de certaines de ces fonctionnalités. Dans ce cas, vous serez
        évidemment prévenus avant toute modification des règles d’utilisation du
        widget Soliguide.
      </p>
      <h2>5. Quels sont nos engagements ?</h2>
      <p>
        Nous faisons tout notre possible pour vous proposer un service de
        qualité, accessible 24/24h, 7/7j, proposant des contenus exacts et
        précis, dénué de tout risque de défaillance technique.
      </p>
      <p>
        Néanmoins, le “risque zéro” n’existant pas en matière informatique,
        notre responsabilité ne peut pas être engagée en cas de préjudice
        pouvant intervenir à cause de l’utilisation de Soliguide car nous ne
        pouvons pas être responsable en cas d’éventuelles erreurs,
        d’indisponibilités, ou d’interruption pouvant intervenir sur Soliguide
        ou dans le cas où un virus serait accessible depuis Soliguide. Nous ne
        sommes pas non plus responsables des délais ou de votre impossibilité à
        respecter vos obligations en application des CGU si le délai ou
        l'impossibilité résulte d'une cause en dehors de notre contrôle
        raisonnable.
      </p>
      <p>
        Nous ne sommes pas non plus responsables des piratages, erreurs ou virus
        qui pourraient être installés sur votre terminal et affecter le service
        ou des démarches ou actions entreprises ou omises par un administrateur
        système.
      </p>
      <p>
        De plus, votre accès à Soliguide ou aux services peut occasionnellement
        être suspendu ou limité pour permettre des réparations, la maintenance,
        ou ajouter une nouvelle fonctionnalité ou un nouveau service.
      </p>
      <p>
        Dans tous les cas, il est expressément convenu que nous ne sommes soumis
        qu’à une obligation de moyens pour la fourniture de Soliguide. Vous
        acceptez d’ailleurs que notre responsabilité ne puisse être engagée
        qu’en cas de faute de notre part, sachant que cette responsabilité ne
        saurait être applicable que pour les dommages directs et prévisibles,
        les préjudices indirects étant exclus, et que nous ne sommes pas
        responsables des pertes commerciales (y compris les pertes de profit,
        bénéfice, contrats, économies espérées, données, ou dépenses
        superflues), qui n'étaient pas prévisibles quand vous avez commencé à
        utiliser Soliguide.
      </p>
      <p>
        Sont considérés comme des dommages indirects notamment les pertes de
        données, de temps, de chiffre d’affaires, de marges, pertes de
        commandes, d’adhérents, d’exploitation, de revenus, ou encore l’atteinte
        à l’image de marque, les résultats escomptés et l’action de tiers et
        d’une manière générale tout trouble quelconque lié à votre activité. De
        même est considérée comme indirecte tout action d’un tiers à votre
        encontre.
      </p>
      <p>
        Par ailleurs, vous êtes informé que tout cas de force majeure suspend
        immédiatement et automatiquement l’exécution de Soliguide. Si les cas de
        force majeure ont une durée d’existence supérieure à deux mois, nous
        nous réservons le droit de suspendre ou de résilier le service sans
        préavis ni indemnité.
      </p>
      <p>
        Sont notamment considérés comme cas de force majeure les évènements
        échappant à notre contrôle, qui ne pouvaient être raisonnablement prévus
        lors de la mise à disposition de Soliguide et dont les effets ne peuvent
        être évités par des mesures appropriées, qui empêchent son utilisation.
      </p>
      <p>
        En particulier, il s’agit de ceux habituellement retenus par la
        jurisprudence des cours et tribunaux français comme la guerre, l’émeute,
        l’incendie, les grèves internes ou externes, lock out, occupation des
        locaux, intempéries, tremblement de terre, inondation, dégât des eaux,
        restrictions légales ou gouvernementales, modifications légales ou
        réglementaires, accidents de toutes natures, épidémie, pandémie, maladie
        touchant plus de 10% du personnel de notre association dans un période
        de deux mois consécutifs, l’absence de fourniture d’énergie, l’arrêt
        partiel ou total du réseau Internet et, de manière plus générale, des
        réseaux de télécommunications privés ou publics, les blocage de routes
        et les impossibilités d’approvisionnement en fournitures et tout autre
        cas indépendant de notre volonté empêchant l’exécution normale de
        Soliguide.
      </p>
      <h2>6. Quelles sont vos obligations et devoirs ?</h2>
      <p>
        Soliguide et ses services sont destinés à une utilisation à des fins
        d’informations uniquement et vous ne devez en aucun cas en faire un
        quelconque usage commercial. Vous vous engagez à ne pas vendre, louer ou
        commercialiser les éléments de Soliguide.
      </p>
      <p>
        Vous vous engagez en outre à ne pas arranger, modifier, décompiler,
        désassembler, procéder à des opérations de reverse engineering,
        traduire, adapter, reproduire, distribuer, diffuser ou transférer toute
        information, tout logiciel, tout produit et d'une manière générale, tout
        élément de Soliguide. Le re-postage, le scrapping, ou l’utilisation
        automatisée ou l’utilisation de grand volume de Soliguide sont
        expressément interdits.
      </p>
      <p>
        Vous vous engagez à vous conformer à tout moment lors de l’utilisation
        de Soliguide (i) aux lois et réglementations nationales et
        internationales en vigueur et (ii) aux CGU.
      </p>
      <p>
        <strong
          >VOUS GARANTISSEZ QUE VOUS N'UTILISEREZ PAS SOLIGUIDE À DES FINS
          ILLICITES OU IMMORALES OU CONTRAIRES À L'UTILISATION PRÉVUE ET/OU À
          LEUR FINALITÉ.
        </strong>
      </p>
      <p>
        En utilisant Soliguide, vous reconnaissez que vous avez uniquement un
        droit d’utilisation pour votre compte. Dans tous les cas, vous vous
        engagez à ne pas, directement ou indirectement :
      </p>
      <ul>
        <li>
          utiliser Soliguide de manière à porter atteinte aux droits de
          propriété intellectuelle et industrielle, au droit à la vie privée
          et/ou au droit à l’image et/ou à tout autre droit d’autrui.
        </li>
        <li>
          créer, utiliser, partager et/ou publier par tout moyen (forum, profil
          public ou autre) tout matériel (texte, propos, images, sons, vidéos,
          etc.) ou contenu qui aurait, notre appréciation exclusive, un
          caractère agressif, menaçant, malveillant, diffamatoire, mensonger,
          pornographique, pédophile, obscène, vulgaire, raciste, xénophobe,
          incitant à la haine, sexuellement explicite, violent, contraire aux
          bonnes mœurs ou de toute autre façon contestable.
        </li>
        <li>
          créer, utiliser, partager et/ou publier par tout moyen (forum, profil
          public ou autre) tout matériel (texte, propos, images, sons, vidéos,
          etc.) qui constituerait la violation d’une obligation de
          confidentialité ou qui inciterait à commettre une activité illicite
          (notamment, piratage, craquage ou diffusion de logiciels contrefaits).
        </li>
        <li>
          mener des activités visant à accéder à des éléments ou fonctionnalités
          des services dont l’utilisation est effectuée sans notre autorisation
          préalable et expresse.
        </li>
        <li>
          arranger, modifier, traduire, adapter, reproduire, indexer, copier
          et/ou extraire toute information, tout logiciel, tout produit, base de
          données ou tout autre élément ou partie de Soliguide, par tout moyen
          sans notre autorisation préalable et expresse.
        </li>
        <li>
          modifier, fausser, bloquer, alourdir de manière anormale, perturber,
          ralentir, et/ou entraver le fonctionnement normal de tout ou partie de
          Soliguide, ou leur accessibilité aux autres utilisateurs, ou le
          fonctionnement des réseaux partenaires, ou tenter de faire tout ce qui
          précède.
        </li>
        <li>
          transmettre ou propager tout virus, cheval de Troie, ver, bombe,
          fichier altéré et/ou dispositif de destruction similaire ou donnée
          corrompue, et/ou organiser, participer ou être impliqué de quelque
          façon que ce soit dans une attaque contre nos serveurs et/ou Soliguide
          et/ou les serveurs et/ou services de nos prestataires et partenaires.
        </li>
        <li>
          harceler les autres utilisateurs de Soliguide, leur envoyer des
          messages non-sollicités à des fins de prospection commerciale,
          utiliser les services à des fins d'enquête, de concours, de ventes
          pyramidales ou opérations similaires, ou d'envoi d’emailing, de spams
          ou de toute publicité ou élément promotionnel non sollicités, et ce à
          des fins commerciales ou non.
        </li>
        <li>
          utiliser le compte d’un autre utilisateur de Soliguide, vous faire
          passer pour une autre personne ou vous attribuer une fausse qualité
          dans les rapports avec toute personne physique ou morale.
        </li>
        <li>
          collecter ou intercepter par tout moyen qui ne serait pas expressément
          permis des données échangées par les autres utilisateurs, ou les
          noms/pseudonymes et/ou mots de passe de tout autre utilisateur.
        </li>
        <li>
          tenter d'obtenir un mot de passe, des informations relatives à un
          compte ou d'autres informations d'ordre privé auprès de tout autre
          utilisateur de Soliguide, et/ou vendre, louer, partager, prêter et/ou
          de toute autre façon transférer à tout tiers votre compte et/ou les
          moyens d’accéder à celui-ci et/ou de toute autre façon faire
          bénéficier tout tiers de votre compte.
        </li>
        <li>
          faire un usage inapproprié du service d'assistance que nous
          fournissons.
        </li>
        <li>
          accéder, utiliser, télécharger via Soliguide ou autrement reproduire
          ou fournir à quiconque (à titre gratuit ou onéreux) tout annuaire des
          utilisateurs de Soliguide ou toute autre information relative aux
          utilisateurs ou à l'utilisation de Soliguide.
        </li>
      </ul>
      <p>
        Si vous constatez un comportement malveillant ou contraire à nos CGU et
        aux intérêts des autres utilisateurs, vous pouvez nous contacter à
        l’adresse
        <a href="mailto:contact@solinum.org">contact@solinum.org</a>.
      </p>
      <h2>7. Votre compte peut-il être suspendu ou désactivé ?</h2>
      <p>
        Nous pouvons limiter, restreindre, suspendre ou interrompre
        définitivement tout ou partie de votre compte, à tout moment, sans
        préavis et sans formalité préalable, en cas de manquement à vos devoirs
        et obligations aux termes des CGU ou à la demande expresse d'une
        autorité administrative ou juridictionnelle, ou encore en cas d'atteinte
        réelle ou supposée à un droit quelconque, le tout sans que cette mesure
        ne puisse être considérée comme un manquement à nos obligations.
      </p>
      <p>
        Toute fermeture de votre compte administrateur entraîne la suppression
        définitive des données personnelles qui nous sont communiquées lors de
        l’ouverture ou de la mise à jour de votre compte. En revanche,
        l’intégralité des données relatives à votre structure qui ne sont pas
        des données personnelles sont conservées dans le cadre de la
        constitution de notre base de données.
      </p>
      <h2>
        8. Quelles sont les règles applicables en matière de propriété
        intellectuelle ?
      </h2>
      <p>
        Nous sommes et restons les titulaires exclusifs de tous les droits de
        propriété intellectuelle sur les marques (ex : Soliguide, Solinum,
        etc.), charte graphique ou logos, ou tout autre élément que vous pouvez
        visualiser lorsque vous utilisez Soliguide.
      </p>
      <p>
        Nous sommes et restons également les titulaires exclusifs de tous les
        droits de propriété intellectuelle et des licences d’éditeurs tiers
        applicables à la structure générale de Soliguide comme, les logiciels
        utilisés pour la faire fonctionner, les algorithmes que nous avons
        développés, les API que nous utilisons, le contenu de Soliguide que nous
        rédigeons et/ou choisissons (ex : texte, son, dessin, Politique de
        confidentialité, Politique Cookies, etc.), notre savoir-faire, etc.
      </p>
      <p>
        Le fait que vous puissiez y avoir accès librement lors de votre
        utilisation n’implique pas que nous vous accordions une quelconque
        licence ou un droit d’utilisation sur les éléments de Soliguide.
      </p>
      <p>
        Vous n’avez donc pas le droit d’utiliser ces éléments, de quelque
        manière que ce soit, entièrement ou partiellement, sans notre
        autorisation préalable et écrite. Toute utilisation de votre part que
        nous n’aurions pas autorisée peut constituer une contrefaçon sanctionnée
        par les articles L335-2 et suivants du Code de la propriété
        intellectuelle et par les articles L713-2 et suivants du même code.
      </p>
      <p>
        Soliguide est également susceptible de mentionner, pour diverses
        raisons, d’autres logos, marques déposées nous appartenant ou à des
        tiers et protégés par le droit de la propriété industrielle. Dès lors,
        toute utilisation non expressément autorisée de ces éléments est
        constitutive de contrefaçon.
      </p>
      <p>
        Conformément aux dispositions de la loi n° 98-536 du 1er juillet 1998
        portant transposition dans le Code de la propriété intellectuelle de la
        directive 96/9 CE du 11 mars 1996 concernant la protection juridique des
        bases de données, nous vous informons que nous somme producteur et
        propriétaire de tout ou partie des bases de données utilisées via
        Soliguide. Par conséquent, toute extraction et/ou réutilisation des
        bases de données au sens des articles L342-1 et L342-2 du Code de la
        propriété intellectuelle est interdite.
      </p>
      <h2>9. Comment utilisons-nous vos données personnelles ?</h2>
      <p>
        Lorsque nous travaillons ensemble et que vous utilisez Soliguide, nous
        n’avons pas d’autre choix que de traiter vos données personnelles
        notamment pour traiter vos demandes, gérer nos relations partenariales,
        etc.
      </p>
      <p>
        Pour faciliter votre compréhension sur la manière dont nous traitons vos
        données, nous avons rédigé une Politique de confidentialité, accessible
        à tout moment sur Soliguide, qui a pour objectif de vous présenter les
        raisons et la manière pour lesquelles nous traitons vos données dans le
        cadre des services que nous fournissons.
      </p>
      <p>
        Dans certains cas, si vous y intégrer des données personnelles (ex :
        numéro de téléphone portable ou nom, prénom d’une personne), nous
        devenons sous-traitants au sens du RGPD. Dans ce cas, vos données sont
        protégées par l’accord de protection des données accessible
        <a routerLink="/accord-protection-donnees">ici</a>.
      </p>
      <h2>10. Quelle est la langue “standard” applicable ?</h2>
      <p>
        Soliguide peut être traduit en différentes langues. Néanmoins, le
        français demeure la langue “standard” de notre association et de notre
        activité. La langue française prévaut donc toujours sur les autres
        versions de Soliguide dans le cadre de notre relation de travail,
        notamment en cas de mauvaise interprétation, contestation, litige, ou
        difficulté d’interprétation sur nos services.
      </p>
      <h2>11. Quelles sont les règles relatives aux liens hypertextes ?</h2>
      <p>
        Soliguide peut contenir des liens hypertextes vers des sites internet ou
        des sites tiers. Vous êtes informé que nous ne sommes ni responsables ni
        engagés sur les offres, actes, contenus, produits, services ou tout
        autre élément disponible via ces liens.
      </p>
      <p>
        Par ailleurs, nous vous remercions par avance de nous signaler tout lien
        hypertexte présent sur Soliguide qui permettrait d’accéder à un site de
        tiers proposant un contenu contraire aux lois ou aux bonnes mœurs.
      </p>
      <h2>12. Convention de preuve</h2>
      <p>
        Nous archivons les communications, fichiers, contrats, bons de commande
        et factures sur un support fiable et durable de manière à constituer une
        copie fidèle et durable. Ces communications, fichiers, contrats, bons de
        commande et factures peuvent être produits à titre de preuve. Sauf
        preuve contraire, les données enregistrées par nos équipes constituent
        la preuve de l’ensemble des échanges passés entre vous et nous.
      </p>
      <h2>13. Nullité</h2>
      <p>
        La nullité d'une des clauses des présentes n'entraîne pas la nullité des
        CGU et les autres clauses continueront à produire leurs effets.
      </p>
      <h2>14. Renonciation</h2>
      <p>
        Le fait, pour Solinum, de ne pas se prévaloir à titre temporaire ou
        permanent d’une ou plusieurs clauses des CGU, n’emportera en aucun cas
        renonciation à s’en prévaloir.
      </p>
      <h2>15. Quel est le droit applicable ?</h2>
      <p>
        Solinum est une association de droit français. En conséquence,
        l’utilisation de Soliguide est soumise au droit français et tout litige
        sera porté exclusivement devant les tribunaux français compétents.
      </p>
      <h2>16. Les conditions générales peuvent-elles être modifiées ?</h2>
      <p>
        Nous nous réservons la possibilité de modifier les CGU à tout moment et
        sans préavis. Nous vous conseillons donc de les consulter le plus
        régulièrement possible.
      </p>
    </div>
  </div>
</main>
