<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<header class="p-3 mb-5 row justify-content-center">
  <img class="col-2" src="assets/images/logo.png" />
</header>

<main class="container text-primary">
  <div class="row justify-content-center">
    <div class="col-8">
      <h1>Accord de protection des données RT_ST (ST)</h1>
      <h2>1. Introduction</h2>
      <p>
        L'Accord de protection des données (ci-après «
        <strong>Accord</strong> ») vise à régir l'utilisation des données à
        caractère personnel du Client, qui agit en tant que responsable du
        traitement (ci-après le « <strong>Client</strong> »), par Solinum, qui
        agit en tant que sous-traitant (ci-après le «
        <strong>Sous-traitant</strong> ») dans le cadre du contrat (ci-après le
        « <strong>Contrat</strong> »).
      </p>
      <h2>2. Déclaration</h2>
      <p>
        Le Sous-traitant déclare et atteste respecter l'intégralité des
        dispositions des règles applicables en matière de protection des données
        qui comprend le Règlement Général pour la Protection des Données
        (ci-après le «
        <strong>RGPD</strong> ») et la loi Informatique et Libertés.
      </p>
      <p>
        Le Sous-traitant déclare présenter toutes les
        <strong>garanties suffisantes</strong> pour répondre aux exigences des
        règles applicables en matière de protection des données et, plus
        particulièrement, pour <strong>garantir la confidentialité</strong> et
        la <strong>protection des données</strong> du Client.
      </p>
      <h2>3. Instructions</h2>
      <p>
        Le Sous-traitant déclare et s'engage à n'utiliser les données à
        caractère personnel du Client que sur
        <strong>instructions documentées</strong> de ce dernier dans le Contrat.
      </p>
      <p>
        Le Client s'engage à informer le Sous-traitant de toute
        <strong>modification</strong> des instructions qui pourraient être
        menées quant à l'utilisation de ses données à caractère personnel.
      </p>
      <p>
        Le Sous-traitant doit notifier au Client, par écrit et dans les plus
        <strong>brefs délais</strong>, si les instructions documentées de ce
        dernier constituent une violation des règles applicables en matière de
        protection des données.
      </p>
      <h2>4. Confidentialité</h2>
      <p>
        Le Sous-traitant déclare et atteste que l'intégralité de ses
        collaborateurs amenés à traiter les données à caractère personnel du
        Client sont engagés par une
        <strong>clause de confidentialité</strong> ou par tout
        <strong>autre acte juridique</strong> permettant de garantir la
        confidentialité des données à caractère personnel du Client.
      </p>
      <p>
        Le Sous-traitant s'engage à
        <strong>former</strong> régulièrement ses collaborateurs sur les règles
        applicables en matière de protection des données.
      </p>
      <h2>5. Sécurité</h2>
      <p>
        Le Sous-traitant atteste et s'engage à garantir la
        <strong>sécurité</strong> des données à caractère personnel du Client et
        à mettre en œuvre toutes les <strong>mesures techniques</strong> et
        <strong>organisationnelles</strong> nécessaires pour prévenir tout
        risque de violation de données.
      </p>
      <h2>6. Violation de données</h2>
      <p>
        Le Sous-traitant s'engage à <strong>notifier</strong> au Client, dans
        les <strong>meilleurs délais</strong> après en avoir pris connaissance,
        toute <strong>violation</strong> de données qui serait susceptible de
        concerner les données à caractère personnel du Client.
      </p>
      <p>
        La notification doit préciser toutes les informations nécessaires au
        traitement de la violation de données par le Client décrites à l'<strong
          >article 28 du RGPD</strong
        >.
      </p>
      <p>
        En cas de violation de données, le Sous-traitant s'engage à prendre
        toutes les mesures nécessaires pour
        <strong>remédier</strong>, et <strong>diminuer</strong> l'impact de la
        violation sur les données à caractère personnel du Client.
      </p>
      <p>
        Sauf accord exprès, préalable et écrit du Client, le Sous-traitant
        <strong>n'est pas autorisé</strong> à effectuer les notifications de
        violation de données à l'autorité de contrôle et aux personnes
        concernées par les traitements réalisés dans le cadre du Contrat.
      </p>
      <h2>7. Aide et assistance en matière de sécurité</h2>
      <p>
        Le Sous-traitant communique au Client toutes les
        <strong>informations nécessaires</strong> et
        <strong>requises</strong> sur les mesures de
        <strong>sécurité techniques</strong> et
        <strong>organisationnelles</strong> à mettre en œuvre dans le cadre du
        Contrat pour garantir la sécurité de ses données à caractère personnel.
      </p>
      <p>
        Le Sous-traitant communique au Client, sur demande écrite, toutes les
        <strong>informations nécessaires</strong> et
        <strong>requises</strong> pour assurer la réalisation d'une
        <strong>analyse d'impact (« PIA »)</strong>.
      </p>
      <p>
        Le Sous-traitant n'est en revanche
        <strong>pas tenu</strong> d'assurer ou d'auditer la sécurité du Client
        ou encore de réaliser des analyses d'impact (« PIA ») à la place et pour
        le compte du Client. Toute demande complémentaire à la communication
        d'informations peut faire l'objet d'un <strong>refus</strong> et,
        éventuellement, d'une prestation complémentaire
        <strong>tarifée</strong>.
      </p>
      <h2>
        8. Aide et assistance en matière de droits des personnes concernées
      </h2>
      <p>
        Le Sous-traitant communique au Client, sur demande écrite, toutes les
        <strong>informations nécessaires</strong> et
        <strong>requises</strong> visant à ce que le Client puisse s'acquitter
        de son obligation de donner suite aux <strong>demandes</strong> des
        personnes concernées.
      </p>
      <p>
        Le Sous-traitant exécute, sur demande écrite du Client, les
        <strong>actions techniques</strong> à entreprendre pour que le Client
        puisse s'acquitter de son obligation de donner suite aux
        <strong>demandes</strong> des personnes concernées.
      </p>
      <p>
        Le Sous-traitant n'est en revanche <strong>pas tenu</strong> de gérer
        les demandes de droits des personnes <strong>à la place</strong> et
        <strong>pour le compte</strong> du Client. Toute demande complémentaire
        visant à assurer une telle gestion peut faire l'objet d'un refus et,
        éventuellement, d'une prestation complémentaire
        <strong>tarifée</strong>.
      </p>
      <h2>9. Sous-traitants ultérieurs</h2>
      <p>
        Le Client accepte que le Sous-traitant <strong>recrute</strong> des
        <strong>Sous-traitants ultérieurs</strong> dans le cadre exclusif de
        l'exécution du Contrat à condition que le Sous-traitant l'informe de
        tout <strong>changement</strong> concernant ces Sous-traitants
        ultérieurs afin que le Client puisse émettre des
        <strong>objections</strong> à ce sujet.
      </p>
      <p>
        Le Sous-traitant s'engage à ne recruter que des Sous-traitants
        ultérieurs qui présentent les
        <strong>garanties nécessaires</strong> et
        <strong>suffisantes</strong> pour assurer la sécurité et la
        confidentialité des données à caractère du Client.
      </p>
      <p>
        Le contrat conclu entre le Sous-traitant et le Sous-traitant ultérieur
        doit contenir des
        <strong>obligations similaires</strong> à celles prévues dans le présent
        Accord.
      </p>
      <p>
        Le Client peut émettre des <strong>objections</strong> par lettre
        recommandée avec accusé de réception i) si le Sous-traitant ultérieur
        est un de ses <strong>concurrents</strong>, ii) si le client et le
        Sous-traitant ultérieur sont dans une situation de
        <strong>précontentieux</strong> ou de <strong>contentieux</strong>, et
        iii) si le Sous-traitant ultérieur a fait l'objet d'une
        <strong>condamnation</strong> par une autorité de contrôle en matière de
        protection des données dans l'année de son recrutement par le
        Sous-traitant. Chacune de ses situations doit être
        <strong>démontrée</strong>.
      </p>
      <p>
        À défaut d'engagement par le Sous-traitant de modifier le Sous-traitant
        ultérieur dans un délai
        <strong>trois mois</strong> à compter de la réception de l'objection, le
        Client dispose de la possibilité de résilier le Contrat sous réserve
        d'un préavis de <strong>six (6) mois</strong> et
        <strong>sans indemnité</strong>.
      </p>
      <p>
        En tout état de cause, le Sous-traitant demeure
        <strong>responsable</strong> des actes du Sous-traitant ultérieur dans
        le cadre du Contrat.
      </p>
      <h2>10. Sort des données à caractère personnel</h2>
      <p>
        Le Client informe le Sous-traitant, par écrit et au plus tard
        <strong>un mois</strong> avant la fin du Contrat, de son
        <strong>choix</strong> (option 1) de lui <strong>restituer</strong> les
        données à caractère personnel puis de les
        <strong>supprimer</strong> ainsi que toutes les copies existantes ou,
        (option 2) de <strong>supprimer directement</strong> les données à
        caractère personnel ainsi que toutes les copies existantes, ou (option
        3) de <strong>transférer</strong> les données à caractère personnel vers
        un nouveau prestataire puis de les supprimer ainsi que toutes les copies
        existantes. Sauf disposition contraire dans le Contrat, l'option 3 doit
        faire l'objet d'un <strong>devis</strong> de la part du Sous-traitant.
      </p>
      <p>
        À défaut d'information par le Client de son choix dans le délai imparti,
        le Sous-traitant se réserve la possibilité de
        <strong>supprimer directement</strong> les données ainsi que toutes les
        copies (option 2).
      </p>
      <p>
        Le Sous-traitant <strong>atteste</strong> au Client de la suppression
        effective des données à caractère personnel et de toutes les copies par
        <strong>écrit simple</strong>.
      </p>
      <h2>11. Audits</h2>
      <p>
        Le Client dispose du droit de réaliser un <strong>audit</strong> sous
        forme de <strong>questionnaire écrit</strong> une fois par an pour
        vérifier le respect du présent Accord. Le questionnaire a la force d'un
        <strong>engagement sur l'honneur</strong> qui engage le Sous-traitant.
      </p>
      <p>
        Le questionnaire peut être communiqué sous n'importe quelle forme au
        Sous-traitant qui s'engage à y répondre dans un délai maximum de
        <strong>deux mois</strong> à compter de sa réception.
      </p>
      <p>
        Le Client dispose également du droit de réaliser un
        <strong>audit sur site</strong>, à ses frais, une fois par an uniquement
        en cas de <strong>violation</strong> de données ou de
        <strong>manquement</strong> aux règles applicables en matière de
        protection des données et au présent Accord, notamment établi par le
        questionnaire écrit.
      </p>
      <p>
        Un audit sur site peut être mené soit par le Client soit par un tiers
        indépendant désigné par le Client et doit être notifié par écrit au
        Sous-traitant au minimum
        <strong>trente (30) jours</strong> avant la réalisation de l'audit.
      </p>
      <p>
        Le Sous-traitant dispose du droit de refuser le choix du tiers
        indépendant si ce dernier est i) un <strong>concurrent</strong> ou ii)
        en <strong>précontentieux</strong> ou <strong>contentieux</strong> avec
        lui. Dans ce cas, le Client s'engage à choisir un nouveau tiers
        indépendant pour réaliser l'audit.
      </p>
      <p>
        Le Sous-traitant peut <strong>refuser</strong> l'accès à certaines zones
        pour des raisons de confidentialité ou de sécurité. Dans ce cas, le
        Sous-traitant effectue l'audit dans ces zones
        <strong>à ses frais</strong> et communique les résultats au Client.
      </p>
      <p>
        En cas d'écart constaté dans le cadre de l'audit, le Sous-traitant
        s'engage à mettre en œuvre,
        <strong>sans délai</strong>, les mesures nécessaires pour être en
        <strong>conformité</strong> avec le présent Accord.
      </p>
      <h2>12. Transferts de données hors de l'Union européenne</h2>
      <p>
        Le Sous-traitant atteste et s'engage à faire son nécessaire pour
        <strong>ne pas transférer</strong> de données à caractère personnel du
        Client <strong>en dehors de l'Union européenne</strong> ou
        <strong>ne pas recruter</strong> de Sous-traitant ultérieur situé
        <strong>en dehors de l'Union européenne</strong>.
      </p>
      <p>
        Néanmoins, dans le cas o&ugrave; de tels transferts s'avéreraient
        nécessaires dans le cadre du Contrat, le Sous-traitant atteste et
        déclare mettre en oeuvre tous les
        <strong>mécanismes</strong> requis pour encadrer ces transferts comme,
        en particulier, conclure des
        <strong>règles d'entreprise contraignantes</strong>
        (« BCR ») ou des <strong>clauses types de protection</strong> des
        données adoptées par la Commission européenne.
      </p>
      <h2>13. Coopération avec l'autorité de contrôle</h2>
      <p>
        Lorsque cela concerne les traitements mis en œuvre dans le cadre du
        Contrat, le Sous-traitant s'engage à fournir, sur demande, l'intégralité
        des informations nécessaires au Client pour qu'il puisse
        <strong>coopérer</strong> avec l'<strong
          >autorité de contrôle compétente</strong
        >.
      </p>
      <h2>14. Nullité</h2>
      <p>
        En cas de <strong>nullité</strong> du Contrat, quelle qu'en soit la
        cause, le Client dispose d'un <strong>délai d'un mois</strong> à compter
        du prononcé de la nullité pour communiquer au Sous-traitant, par écrit,
        sa décision quant au sort de ses données, conformément à l'article 10 du
        présent Accord.
      </p>
      <h2>15. Contact</h2>
      <p>
        Le Client et le Sous-traitant désignent chacun un
        <strong>interlocuteur</strong> qui est en charge du présent Accord et
        qui est le destinataire des différentes notifications et communications
        devant intervenir dans le cadre de l'Accord.
      </p>
      <p>
        Si un
        <strong>délégué à la protection des données</strong>
        (« DPO ») a été nommé par le Client et/ou le Sous-traitant,
        l'interlocuteur sera nécessairement le délégué à la protection des
        données.
      </p>
      <h2>16. Révision</h2>
      <p>
        Le Client se réserve la possibilité de modifier le présent Accord en cas
        d'<strong>évolution</strong> des règles applicables en matière de
        protection des données qui auraient pour effet de modifier l'une de ses
        dispositions.
      </p>
      <h2>17. Loi applicable</h2>
      <p>
        Nonobstant toute disposition contraire prévue dans le Contrat, le
        présent Accord est soumis au <strong>droit français</strong>. Tout
        litige relatif à l'exécution du présent Accord est de la compétence
        exclusive des tribunaux du ressort de la
        <strong>Cour d'appel du lieu de domiciliation du Sous-traitant</strong>.
      </p>
      <p>
        <br />
        Publié le 01/02/2022
      </p>
      <p><em>Certifiée conforme par Dipeeo ®</em></p>
    </div>
  </div>
</main>
